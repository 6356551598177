import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import KeywordPigLogo from "../keyword-pig-logo.png";
import { KeywordData } from "../keyword-pig.slice";
import KeywordPigResultsTable from "./results-table";

const KeywordPigResultsPDF = (props: any) => {
  const dispatch = useAppDispatch();
  const toolData = useSelector((state: RootState) => state.keywordPig);
  const currentDate = new Date().toLocaleDateString();
  return (
    <>
      {" "}
      <Stack sx={{ padding: 4, margin: 2, backgroundColor: "#fff" }}>
        <Stack flexDirection="row" alignItems="center">
          <Box>
            <Stack flexDirection="row" alignItems="baseline">
              <Typography
                variant="h4"
                component="h4"
                sx={{ fontWeight: "bold" }}
              >
                Keyword Analysis Report
              </Typography>
              <Typography variant="subtitle1" sx={{ px: 3 }}>
                {currentDate}
              </Typography>
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: 2, color: "gray" }}
            >
              This is a real keyword assessment considering various market
              factors.
            </Typography>
          </Box>
          <div style={{ flexGrow: 1 }}></div>
          <Box sx={{ mx: 2, my: 2 }}>
            <img src={KeywordPigLogo} alt="Keyword Pig Pro" width={"200px"} />
          </Box>
        </Stack>
        <Divider
          sx={(theme) => {
            return {
              height: "3px",
              mb: 3,
              width: "100%",
              background: theme.palette.primary.main,
            };
          }}
        />
        <Typography
          variant="h5"
          component="h5"
          sx={(theme) => {
            return {
              color: theme.palette.primary.main,
              my: 3,
            };
          }}
        >
          Search Term: {(toolData.tool.data?.data as KeywordData).niche}
        </Typography>
        <KeywordPigResultsTable hideButtons />
      </Stack>
    </>
  );
};

export default KeywordPigResultsPDF;
