import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { editorSlice } from "..";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

type Props = {
  open: boolean;
  onClose: any;
  update?: boolean;
};

type CreateArticleData = {
  keyword: string;
};

export const CreateArticleDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
    setValue,
  } = useForm<CreateArticleData>({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.update) {
      setValue("keyword", editorState.article?.keyword as string);
    }
  }, [props]);

  const getProject = async () => {
    try {
      const response: any = await axios.post(`${apiUrl}/project/get`, {
        id: editorState.project?.id,
      });
      const result = response.data.result;
      dispatch(editorSlice.actions.setProject(result));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting project...");
      }
    }
  };

  const onSubmit: SubmitHandler<CreateArticleData> = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(props.update ? `${apiUrl}/article/update` : `${apiUrl}/article/create`, {
        projectId: props.update ? undefined : editorState.project?.id,
        id: props.update ? editorState.article?.id : undefined,
        keyword: data.keyword,
      });
      const article = response.data.result;
      toast.success(`Successfully ${props.update ? "updated" : "created"} article`);
      await getProject();
      dispatch(editorSlice.actions.setArticle(article));
      props.update
        ? dispatch(editorSlice.actions.setUpdateArticleDialogOpened(false))
        : dispatch(editorSlice.actions.setArticleDialogOpened(false));
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
    }
    reset();
  };

  const handleImproveKeyword = async () => {
    try {
      toast.success("Improving headline...");
      setLoading(true);
      const response = await axios.post(`${apiUrl}/article/improve-keyword`, {
        keyword: getValues("keyword"),
      });
      const result = response.data.result;
      setValue("keyword", result.keyword);
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error improving headline");
      }
    }
    setLoading(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {props.update ? "Update The Article" : "Create a New Article"}
          <DialogContentText>Please enter the information below to create a new article</DialogContentText>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Headline"
              disabled={loading}
              {...register("keyword", { required: true, minLength: 1 })}
            />
            {formState.errors.keyword && (
              <FormHelperText error={true}>Please enter a headline for your article!</FormHelperText>
            )}
            <div
              style={{
                display: getValues("keyword") ? "block" : "none",
              }}
            >
              <Button
                onClick={() => handleImproveKeyword()}
                startIcon={
                  <SvgIcon fontSize="small">
                    <AutoAwesomeIcon />
                  </SvgIcon>
                }
                sx={{ maxWidth: "200px" }}
                disabled={loading}
              >
                Improve Headline
              </Button>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          {props.update && (
            <Button color="inherit" onClick={onClose}>
              Close
            </Button>
          )}
          <LoadingButton loading={loading} type="submit" variant="contained" disabled={!formState.isValid}>
            {props.update ? "Update Article" : "Create Article"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
