import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../core/hooks/useStore";
import { ToolItem } from "./tools-data";

const ToolsCard = ({
  tool,
  purchased,
  canPurchase,
}: {
  tool: ToolItem;
  purchased: boolean;
  canPurchase: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkoutUrl = canPurchase
    ? tool.checkout.checkout
    : tool.checkout.dependentsNotMetCheckout;

  if (!checkoutUrl) return <></>;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ my: "10px" }}>
        <CardMedia sx={{ height: 140 }} image={tool.img} title={tool.name} />
        <CardContent sx={{ minHeight: "150px" }}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: (theme) => theme.palette.grey[700],
              display: "flex",
              alignItems: "center",
            }}
          >
            {purchased ? (
              <>
                <SvgIcon sx={{ fontSize: "15px", mr: 1 }}>
                  <CheckCircleIcon sx={{ color: "green" }} />
                </SvgIcon>
                Purchased{" "}
              </>
            ) : (
              <>
                <SvgIcon sx={{ fontSize: "15px", mr: 1 }}>
                  <ErrorIcon />
                </SvgIcon>
                Not Purchased Yet
              </>
            )}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {tool.name}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {tool.desc}
          </Typography>

          <Typography
            variant="body2"
            component="div"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              fontWeight: "bold",
              color: "darkRed",
            }}
          >
            {/* {!canPurchase ? (
              <>* Requires Purchase of Main Product First!</>
            ) : (
              <>&nbsp;</>
            )} */}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          {purchased && (
            <>
              <Button
                size="small"
                href={tool.path}
                sx={{ cursor: "pointer" }}
                color="primary"
                variant="outlined"
                startIcon={<OpenInNewIcon />}
              >
                Open
              </Button>
            </>
          )}
          {!purchased && (
            <>
              <Button
                size="small"
                href={checkoutUrl}
                target="_blank"
                sx={{ cursor: "pointer" }}
                color="primary"
                variant="outlined"
                startIcon={<OpenInNewIcon />}
              >
                Buy Now
              </Button>
            </>
          )}

          <Button
            size="small"
            href={checkoutUrl}
            target="_blank"
            sx={{ cursor: "pointer", px: 2 }}
            color="secondary"
          >
            View Details
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ToolsCard;
