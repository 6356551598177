import { Button, ButtonGroup } from "@mui/material";
import { ControlledBubbleMenu, RichTextEditorRef } from "mui-tiptap";
import { DOMParser } from "prosemirror-model";
import { RefObject, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AddImageDialog } from "../dialog/add-image-dialog";
type Props = {
  editorRef: RefObject<RichTextEditorRef>;
  onUpdate: (editor: any) => void;
};
export const SelectionBubbleMenu = (props: Props) => {
  const [addImagePopupOpened, setAddImagePopupOpened] =
    useState<boolean>(false);

  const editor = props.editorRef.current?.editor;
  if (!editor) return <></>;

  const handleCloseAddImagePopup = () => {
    setAddImagePopupOpened(false);
  };
  const isImageSelected = () => {
    const { from, to } = editor.state.selection;
    let isImage = false;
    editor.state.doc.nodesBetween(from, to, (node) => {
      if (node.type.name === "image") {
        isImage = true;
      }
    });
    return isImage;
  };

  function elementFromString(value: string): HTMLElement {
    const element = document.createElement("div");
    element.innerHTML = value.trim();
    return element;
  }

  const insertHTML = (
    { state, view }: { state: any; view: any },
    value: string
  ): void => {
    const { selection } = state;
    const element = elementFromString(value);
    const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
    const transaction = state.tr.insert(selection.anchor, slice.content);
    view.dispatch(transaction);
    props.onUpdate(props.editorRef.current);
  };

  const { from, to } = editor.state.selection;
  const text = editor.state.doc.textBetween(from, to);

  const handleRemoveImage = async () => {
    editor.chain().focus().deleteSelection().run();
    props.onUpdate(props.editorRef.current);
  };

  return (
    <>
      <ErrorBoundary fallback={<></>}>
        <ControlledBubbleMenu
          editor={editor}
          open={!editor.state.selection.empty}
        >
          <ButtonGroup>
            <Button
              onClick={() => setAddImagePopupOpened(true)}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              {isImageSelected() ? "Change Image" : "Add Image"}
            </Button>
            {isImageSelected() && (
              <Button
                onClick={handleRemoveImage}
                className={editor.isActive("bold") ? "is-active" : ""}
              >
                Remove Image
              </Button>
            )}
          </ButtonGroup>
        </ControlledBubbleMenu>
        <AddImageDialog
          open={addImagePopupOpened}
          onClose={handleCloseAddImagePopup}
          onInsertImage={(image) => {
            if (image) {
              const html = `<img src="${image.url}" />${image.attribution ? image.attribution : ""
                }`;
              if (isImageSelected()) {
                editor.chain().insertContent(html).run();
              } else {
                insertHTML(
                  editor,
                  `<img src="${image.url}" />${image.attribution ? image.attribution : ""
                  }`
                );
              }
            }
          }}
          defaultSearchValue={isImageSelected() ? "" : text}
        />
      </ErrorBoundary>
    </>
  );
};
