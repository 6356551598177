import { createSlice } from "@reduxjs/toolkit";

interface TrainingState { }

const initialState: TrainingState = {};

const trainingSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
});

export type { TrainingState };
export const { } = trainingSlice.actions;
export default trainingSlice;
