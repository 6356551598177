import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AuthLayout from "../../../common/layouts/auth";
import { RootState } from "../../../core/store/store";

type Inputs = {
  email: string;
};

const Reset = () => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/user/reset-send-code`, {
        email: data.email.trim(),
      });
      toast.success("Check your email!");
      navigate(`/recover/${data.email.toLowerCase()}`);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error resetting password...");
      }
    }
    reset();
    setLoading(false);
  };

  return (
    <AuthLayout>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            mb: 3,
          }}
        >
          <Typography variant="h4">Reset Password</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button href="/login">Login</Button>
        </Box>
        <Stack spacing={2}>
          <TextField
            autoFocus
            fullWidth
            label="Email Address"
            type="email"
            error={errors.email != null}
            required
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </Stack>
        <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
          Reset password
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Reset;
