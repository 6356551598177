import HtmlIcon from "@mui/icons-material/SourceSharp";
import { MenuItem, SelectChangeEvent, useTheme } from "@mui/material";
import copy from "copy-to-clipboard";
import {
  MenuButton,
  MenuButtonAddImage,
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelect,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  RichTextEditorRef,
  isTouchDevice,
  useRichTextEditorContext,
} from "mui-tiptap";
import { NodeHtmlMarkdown } from "node-html-markdown";
import { ReactNode, RefObject, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { AddImageDialog } from "../dialog/add-image-dialog";
import { EditCodeDialog } from "../dialog/edit-code-dialog";
import useProject from "../panel/use-project";
import editorSlice from "./editor.slice";

type Props = {
  editorOpened: "outline" | "article";
  editorRef: RefObject<RichTextEditorRef>;
};

enum CopyButtonEnum {
  FORMATTING = "formatting",
  HTML = "html",
  MARKDOWN = "markdown",
  TEXT = "text",
}

export default function EditorMenuControls(props: Props) {
  const theme = useTheme();
  const editor = useRichTextEditorContext();
  const [addImagePopupOpened, setAddImagePopupOpened] =
    useState<boolean>(false);
  const [editCodeDialogOpened, setEditCodeDialogOpened] =
    useState<boolean>(false);
  const [copyButtonValue, setCopyButtonValue] = useState<string>("");
  const editorState = useSelector((state: RootState) => state.editor);
  const dispatch = useAppDispatch();
  const project = useProject(props.editorRef);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);

  const handleCloseAddImagePopup = () => {
    setAddImagePopupOpened(false);
  };

  const handleCloseEditCodeDialog = () => {
    setEditCodeDialogOpened(false);
  };

  const handleOpenEditCodeDialog = () => {
    setEditCodeDialogOpened(true);
  };

  const handleUpdateCode = async (code: string) => {
    setCodeLoading(true);
    const codeFormatFixed = code.split("\n").join("");
    let article = Object.assign({}, editorState.article);
    if (article) {
      article.content = codeFormatFixed;
      dispatch(editorSlice.actions.updateLocalArticleContent(codeFormatFixed));
      await project.handleSave({
        id: editorState.article?.id as string,
        content: codeFormatFixed,
      });
      toast.success("Successfully updated article");
      handleCloseEditCodeDialog();
    }
    setCodeLoading(false);
  };

  const handleCopyButton = (event: SelectChangeEvent) => {
    const copyOption: string = event.target.value;
    const editorHTML = editor?.getHTML();
    const editorText = editor?.getText();

    switch (copyOption) {
      case CopyButtonEnum.FORMATTING:
        // Assuming FORMATTING means you want the HTML content
        if (editorHTML) copy(editorHTML, { format: "text/html" });
        break;
      case CopyButtonEnum.HTML:
        if (editorHTML) copy(editorHTML, { format: "text/plain" });
        break;
      case CopyButtonEnum.MARKDOWN:
        const nhm = new NodeHtmlMarkdown();
        const markdown = nhm.translate(editorHTML || "");
        copy(markdown, { format: "text/plain" });
        break;
      case CopyButtonEnum.TEXT:
        if (editorText) copy(editorText, { format: "text/plain" });
        break;
      default:
        break;
    }
  };

  if (props.editorOpened === "outline") {
    return (
      <MenuControlsContainer>
        <MenuDivider />

        <MenuSelectHeading />

        <MenuDivider />

        <MenuButtonRemoveFormatting />

        <MenuDivider />

        <MenuButtonUndo />
        <MenuButtonRedo />
      </MenuControlsContainer>
    );
  } else {
    return (
      <MenuControlsContainer>
        <MenuDivider />

        <MenuSelectHeading />

        <MenuDivider />

        <MenuSelectFontSize />

        <MenuDivider />

        <MenuButtonBold />

        <MenuButtonItalic />

        <MenuButtonUnderline />

        <MenuButtonStrikethrough />

        <MenuButtonSubscript />

        <MenuButtonSuperscript />

        <MenuDivider />

        <MenuButtonTextColor
          defaultTextColor={theme.palette.text.primary}
          swatchColors={[
            { value: "#000000", label: "Black" },
            { value: "#ffffff", label: "White" },
            { value: "#888888", label: "Grey" },
            { value: "#ff0000", label: "Red" },
            { value: "#ff9900", label: "Orange" },
            { value: "#ffff00", label: "Yellow" },
            { value: "#00d000", label: "Green" },
            { value: "#0000ff", label: "Blue" },
          ]}
        />

        <MenuButtonHighlightColor
          swatchColors={[
            { value: "#595959", label: "Dark grey" },
            { value: "#dddddd", label: "Light grey" },
            { value: "#ffa6a6", label: "Light red" },
            { value: "#ffd699", label: "Light orange" },
            // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
            { value: "#ffff00", label: "Yellow" },
            { value: "#99cc99", label: "Light green" },
            { value: "#90c6ff", label: "Light blue" },
            { value: "#8085e9", label: "Light purple" },
          ]}
        />

        <MenuDivider />

        <MenuButtonEditLink />

        <MenuDivider />

        <MenuSelectTextAlign />

        <MenuDivider />

        <MenuButtonOrderedList />

        <MenuButtonBulletedList />

        <MenuButtonTaskList />

        {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
        {isTouchDevice() && (
          <>
            <MenuButtonIndent />

            <MenuButtonUnindent />
          </>
        )}

        <MenuDivider />

        <MenuButtonBlockquote />

        <MenuDivider />

        <MenuButtonCode />

        <MenuButtonCodeBlock />

        <MenuDivider />

        {/* <MenuButtonImageUpload
        onUploadFiles={(files) =>
          // For the sake of a demo, we don't have a server to upload the files
          // to, so we'll instead convert each one to a local "temporary" object
          // URL. This will not persist properly in a production setting. You
          // should instead upload the image files to your server, or perhaps
          // convert the images to bas64 if you would like to encode the image
          // data directly into the editor content, though that can make the
          // editor content very large.
          files.map((file) => ({
            src: URL.createObjectURL(file),
            alt: file.name,
          }))
        }
      />
       */}

        <MenuButtonAddImage
          onClick={() => {
            setAddImagePopupOpened(true);

            // if (url) {
            //   editor?.chain().focus().setImage({ src: url }).run();
            // }
          }}
        />

        <AddImageDialog
          open={addImagePopupOpened}
          onClose={handleCloseAddImagePopup}
          onInsertImage={(image) => {
            if (image) {
              editor
                ?.chain()
                .focus()
                .insertContent(
                  `<img src="${image.url}" />${image.attribution ? image.attribution : ""
                  }`
                )
                .run();
            }
          }}
        />

        <MenuDivider />

        <MenuButtonHorizontalRule />

        <MenuButtonAddTable />

        <MenuDivider />

        <MenuButtonRemoveFormatting />

        <MenuDivider />

        <MenuButtonUndo />
        <MenuButtonRedo />

        <MenuSelect
          disabled={!editor?.isEditable}
          displayEmpty
          renderValue={(selected) => {
            let result: ReactNode | undefined;
            if (selected === "") {
              // Handle the deprecated `emptyValue` label name, falling back to the
              // newer `labels.empty`, and finally our default empty label
              return <span>Copy as…</span>;
            } else if (selected === CopyButtonEnum.HTML) {
            } else if (selected === CopyButtonEnum.MARKDOWN) {
            } else if (selected === CopyButtonEnum.TEXT) {
            }
            return result ?? selected;
          }}
          onChange={handleCopyButton}
          value={copyButtonValue}
        >
          <MenuItem aria-label="Copy..." value={CopyButtonEnum.FORMATTING}>
            Copy (Normal)
          </MenuItem>
          <MenuItem value={CopyButtonEnum.HTML}>Copy to HTML</MenuItem>
          <MenuItem value={CopyButtonEnum.MARKDOWN}>Copy to Markdown</MenuItem>
          <MenuItem value={CopyButtonEnum.TEXT}>Copy to Text</MenuItem>
        </MenuSelect>
        <MenuButton
          tooltipLabel="Code Editor"
          // tooltipShortcutKeys={["mod", "Shift", "8"]}
          IconComponent={HtmlIcon}
          disabled={!editor?.isEditable}
          onClick={handleOpenEditCodeDialog}
        />
        <EditCodeDialog
          open={editCodeDialogOpened}
          onClose={handleCloseEditCodeDialog}
          code={editorState.localArticle?.content || ""}
          onUpdateCode={handleUpdateCode}
          loading={codeLoading}
        />
      </MenuControlsContainer>
    );
  }
}
