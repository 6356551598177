import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { useDialog } from "../../hooks/use-dialog";
import { PlanData } from "../../interfaces/planData.interface";
import { ChangeBillingDialog } from "./change-billing-dialog";

type Props = {
  disableSelectExistingPlan?: boolean;
};

const BillingPlan = (props: Props) => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<PlanData[]>([]);
  const [plan, setPlan] = useState<string | null>(null);
  const [period, setPeriod] = useState<string>("monthly");

  const handlePlanChange = useCallback((event: any, value: any) => {
    setPlan(value);
  }, []);

  const handlePeriodChange = useCallback((event: any, period: any) => {
    if (period) {
      setPeriod(period);
    }
  }, []);

  const getPlans = async () => {
    try {
      setLoading(true);
      const response: any = await axios.post(`${apiUrl}/user/plans`, {});
      const result = response.data.result;
      const plans = result.plans;
      setPlans(plans);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting plans...");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlans();
  }, [authState.user?.email]);

  const handleUpgradeClicked = () => {
    const selectedPlan = plans.find((p) => p._id === plan);
    const appendStr = `&email=${authState.user?.email}&first_name=${authState.user?.firstName}&last_name=${authState.user?.lastName}`;
    window.open(selectedPlan?.checkoutUrl + appendStr || "", "_blank");
  };

  const changeBillingPlanDialog = useDialog();

  return (
    <Stack>
      {loading && <CircularProgress size={15} sx={{ mr: "10px" }} color="inherit" />}

      <Card>
        <CardHeader title="Subscription Plan" />
        <Divider />
        <Stack
          alignItems="center"
          justifyContent={"space-between"}
          direction="row"
          spacing={3}
          sx={{
            px: 3,
            py: 2,
          }}
        >
          <Typography variant="subtitle2">Billing</Typography>
          <ToggleButtonGroup
            color="primary"
            value={period}
            exclusive
            onChange={handlePeriodChange}
            aria-label="Period"
            size="small"
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Divider />
        <RadioGroup name="plan" onChange={handlePlanChange} value={plan}>
          {plans
            .filter((p) => {
              if (period === "monthly" && p.billingPeriodType === "MONTH") {
                return true;
              } else if (period === "yearly" && p.billingPeriodType === "YEAR") {
                return true;
              } else {
                return false;
              }
            })
            .sort((a, b) => a.pricingDetails.price - b.pricingDetails.price)
            .map((option: PlanData) => {
              return (
                <Fragment key={option._id}>
                  <FormControlLabel
                    disableTypography
                    control={<Radio />}
                    label={
                      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                        <div>
                          <Typography>{option.name}</Typography>
                          <Typography color="text.secondary" variant="caption">
                            {option.description}
                          </Typography>
                        </div>
                        {period === "monthly" ? (
                          <Typography variant="h5">
                            ${option.pricingDetails.price}/$
                            {option.pricingDetails.intervalLabel}
                          </Typography>
                        ) : (
                          <Typography variant="h5">
                            ${option.pricingDetails.price}/$
                            {option.pricingDetails.intervalLabel}
                          </Typography>
                        )}
                      </Stack>
                    }
                    sx={{
                      m: 0,
                      px: 3,
                      py: 1.5,
                    }}
                    value={option._id}
                  />
                  <Divider />
                </Fragment>
              );
            })}
        </RadioGroup>
        <CardActions>
          <Button variant="contained" onClick={handleUpgradeClicked}>
            Upgrade Plan
          </Button>
        </CardActions>
        <ChangeBillingDialog
          onClose={changeBillingPlanDialog.handleClose}
          open={changeBillingPlanDialog.open}
          firstName={authState.user?.firstName || ""}
          lastName={authState.user?.lastName || ""}
          email={authState.user?.email || ""}
          newPKS={true}
        />
      </Card>
    </Stack>
  );
};

export default BillingPlan;
