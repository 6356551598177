import { MenuItem, Stack, TextField } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { integrationsSlice } from "../integrations";
import projectsSlice from "./projects.slice";

const FilterIntegrations = () => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationState = useSelector((state: RootState) => state.integrations);
  const integrationFilter = useSelector((state: RootState) => state.projects.filter?.integrationFilter);

  const getIntegrations = async () => {
    try {
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
  };

  const handleIntegrationFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(projectsSlice.actions.setIntegrationFilter(event.target.value === "all" ? "" : event.target.value));
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  return (
    <>
      <Stack direction="row" alignItems="center">
        {/* <Typography variant="subtitle1" sx={{ marginRight: "1rem" }}>
          Integration:
        </Typography> */}
        <TextField
          fullWidth
          select
          // disabled={props.disabled}
          value={integrationFilter ? integrationFilter : "all"}
          onChange={handleIntegrationFilterChange}
        >
          <MenuItem value={"all"}>All Websites</MenuItem>
          {integrationState.integrations &&
            integrationState.integrations.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.data.domain}
              </MenuItem>
            ))}
        </TextField>
      </Stack>
    </>
  );
};

export default FilterIntegrations;
