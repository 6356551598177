import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Card, CardContent, FormHelperText, Grid, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { authSlice } from "../auth/auth";

type PasswordForm = { oldPassword: string; newPassword: string };

const AccountPassword = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
    setValue,
  } = useForm<PasswordForm>({
    mode: "onChange",
  });
  useEffect(() => {
    document.title = "Wordfrom AI - Account";
  }, []);
  const onSubmitPassword: SubmitHandler<PasswordForm> = async (data) => {
    try {
      setLoading(true);
      const response: any = await axios.post(`${apiUrl}/user/update-password`, {
        oldPassword: getValues("oldPassword").trim(),
        newPassword: getValues("newPassword").trim(),
      });
      const result = response.data.result;
      dispatch(authSlice.actions.setUser(result));
      toast.success("Successfully updated password!");
      reset();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error Occurred");
      }
    }
    setLoading(false);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} sx={{ padding: "40px" }}>
          <Grid xs={12} md={5}>
            <Typography variant="h5" sx={{ my: "2px" }}>
              Change Password
            </Typography>
          </Grid>
          <Grid xs={12} md={7}>
            <form onSubmit={handleSubmit(onSubmitPassword)}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Old password"
                  type="password"
                  {...register("oldPassword", { required: true, minLength: 4 })}
                />
                <TextField
                  fullWidth
                  label="New password"
                  type="password"
                  {...register("newPassword", { required: true, minLength: 4 })}
                />
              </Stack>
              {errors.oldPassword && (
                <FormHelperText error sx={{ mt: 2 }}>
                  Please enter your old password and new password!
                </FormHelperText>
              )}
              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={!formState.isValid}
                >
                  Change Password
                </LoadingButton>
              </Box>
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountPassword;
