import axios from "axios";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { useDebounce } from "../../../hooks/use-debounce";
import { ArticleResponse } from "../../../interfaces/article.interface";
import { ProjectResponse } from "../../../interfaces/projects.interface";
import toolsSlice, { ToolJobData } from "./headline-pig.slice";

const useTool = <T,>() => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();

  const runFetchLoop = async (toolId: string) => {
    dispatch(toolsSlice.actions.setStatus("creating"));

    let continueRun = true;
    while (continueRun) {
      await new Promise((resolve) => setInterval(resolve, 1000));
      try {
        const response: any = await axios.post(`${apiUrl}/tools/get-job`, {
          toolId,
        });
        const result = response.data.result.tool as ToolJobData;
        if (result.finishedAt) {
          dispatch(toolsSlice.actions.setCreateDialogOpened(false));
          dispatch(toolsSlice.actions.setData(result));
          dispatch(toolsSlice.actions.setStatus("created"));
          continueRun = false;
        }

        // if (result.generating) {
        //   generateArticle.runFetchGenerationLoopDebounced(
        //     result.id,
        //     result.status === "creating_outline" ? "outline" : "article"
        //   );
        // } else {
        //   editorRef.current?.editor
        //     ?.chain()
        //     .setContent(response.data.result.content)
        //     .run();
        // }
      } catch (e: any) {
        if (e.response?.data?.error?.message) {
          toast.error("Error: " + e.response.data.error.message);
        } else if (e.request) {
          toast.dismiss();
          toast.error("Network error occurred...");
        } else {
          toast.error("Error fetching job...");
        }
      }
    }
  };

  const createJob = async (data: { type: string; data: any }) => {
    try {
      dispatch(toolsSlice.actions.setStatus("loading"));
      const response: any = await axios.post(`${apiUrl}/tools/create-job`, data);
      const result = response.data.result;
      dispatch(toolsSlice.actions.setCreateDialogOpened(false));
      dispatch(toolsSlice.actions.setData(response.data.result.tool));
      dispatch(toolsSlice.actions.setStatus("creating"));
      runFetchLoop(response.data.result.tool._id);
      return result.generating;
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error running query...");
      }
    }
  };

  const listJobs = async (type: string) => {
    try {
      const response: any = await axios.post(`${apiUrl}/tools/list-jobs`, {
        type,
      });
      const jobs = response.data.result.jobs as ToolJobData[];
      return jobs;
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error running query...");
      }
    }
  };

  return { createJob, listJobs };
};

export default useTool;
