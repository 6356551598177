import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../core/hooks/useUserData";
import { RootState } from "../../../core/store/store";
import { TemplateResponse } from "../../../interfaces/template.interface";
import useTool from "./use-keyword-pig";

type Props = {
  open: boolean;
  onClose: any;
  defaultNiche?: string;
};

type CreateJobData = {
  niche: string;
};

export const KeywordPigCreateDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Array<TemplateResponse>>([]);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();
  const tool = useTool();
  const userData = useUserData();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<CreateJobData>({
    mode: "onChange",
    defaultValues: {
      niche: props.defaultNiche,
    },
  });

  const onSubmit: SubmitHandler<CreateJobData> = async (data) => {
    try {
      await tool.createJob({ type: "KEYWORD", data });
      await userData.getUser();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating job");
      }
    }
    reset();
    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>New Search</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Niche"
              disabled={loading}
              {...register("niche", { required: true, minLength: 1 })}
            />
            {formState.errors.niche ? (
              <FormHelperText error={true}>Please enter an niche name!</FormHelperText>
            ) : (
              <FormHelperText>Enter a niche that you want to create a list of keywords about.</FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton loading={loading} type="submit" variant="contained" disabled={!formState.isValid || loading}>
            Start Search
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
