import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePDF } from "react-to-pdf";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import KeywordPigRecentSearches from "./keyword-pig-select-recent-searches";
import toolsSlice, { KeywordJobData } from "./keyword-pig.slice";
import KeywordPigResultsPDF from "./results/results-pdf";

interface KeywordPigNavHeaderProps { }

const KeywordPigNavHeader: React.FC<KeywordPigNavHeaderProps> = () => {
  const dispatch = useAppDispatch();
  const [selection, setSelection] = useState<string>("");
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const toolsState = useSelector((state: RootState) => state.keywordPig);
  const { toPDF, targetRef } = usePDF({
    filename: `keyword-report-${new Date().getTime()}.pdf`,
  });
  const navigate = useNavigate();

  const exportOpen = Boolean(exportAnchorEl);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelection(event.target.value);
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleBackClick = () => {
    if (["created", "creating"].includes(toolsState.tool.status)) {
      dispatch(toolsSlice.actions.setStatus("initialized"));
    } else {
      navigate("/tools");
    }
  };

  const handleExportPDF = () => {
    toPDF();
    handleExportClose();
  };

  const handleExportCSV = () => {
    handleExportClose();
  };

  const results = toolsState.tool.data
    ? (toolsState.tool.data as KeywordJobData).data.keywords
    : null;

  const csvData = !results
    ? []
    : [
      ["Keywords"],
      ...results?.map((result) => {
        return [result];
      }),
    ];

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: "white", boxShadow: 3, my: 3, borderRadius: "6px" }}
    >
      <Toolbar>
        <Button
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={handleBackClick}
        >
          Back
        </Button>

        {/* Spacer to push items to the right */}
        <div style={{ flexGrow: 1 }}></div>

        {/* Select and Save Button */}
        {["initialized"].includes(toolsState.tool.status) && (
          <KeywordPigRecentSearches />
        )}
        {["created"].includes(toolsState.tool.status) && (
          <KeywordPigRecentSearches selected={toolsState.tool.data?._id} />
        )}
        {toolsState.tool.status === "created" && (
          <Button
            color="primary"
            onClick={handleExportClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Export Results {/* Export Button with Dropdown */}
          </Button>
        )}
        <Menu
          anchorEl={exportAnchorEl}
          keepMounted
          open={exportOpen}
          onClose={handleExportClose}
        >
          <MenuItem onClick={handleExportPDF}>Export to PDF</MenuItem>
          {exportOpen && (
            <Box
              style={{
                position: "absolute",
                top: "-10000px",
              }}
            >
              <div ref={targetRef}>
                <KeywordPigResultsPDF />
              </div>
            </Box>
          )}
          <CSVLink
            data={csvData}
            filename={`keyword-report-${new Date().getTime()}.csv`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem onClick={handleExportCSV}>Export to CSV</MenuItem>
          </CSVLink>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default KeywordPigNavHeader;
