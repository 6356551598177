import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../common/components/loader";
import DashboardLayout from "../../../common/layouts/dashboard";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { homeSlice } from "../../home";
import { KeywordPigCreateDialog } from "./keyword-pig-create-dialog";
import KeywordPigDesc from "./keyword-pig-desc";
import KeywordPigHeader from "./keyword-pig-header";
import KeywordPigLoading from "./keyword-pig-loading";
import KeywordPigLocked from "./keyword-pig-locked";
import KeywordPigNavHeader from "./keyword-pig-nav-bar";
import toolsSlice from "./keyword-pig.slice";
import KeywordPigResults from "./results/results";

const KeywordPig = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const toolsState = useSelector((state: RootState) => state.keywordPig);
  const navigate = useNavigate();
  const hasAccess = auth.tools?.keywordTool;

  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("keyword-tool"));
    dispatch(toolsSlice.actions.setType("KEYWORD"));
    if (hasAccess) {
      dispatch(toolsSlice.actions.setStatus("initialized"));
    } else {
      navigate("/tools");
      // dispatch(toolsSlice.actions.setStatus("unauthorized"));
    }
  }, [homeSlice]);
  const [loading, setLoading] = useState(true);

  const KeywordPigLoader = () => {
    return <Loader />;
  };

  const handleOpen = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(true));
  };

  const handleClose = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(false));
  };

  return (
    <DashboardLayout active={"keyword-pig"} name="Keyword Pig">
      <Box>
        {toolsState.tool.status === "loading" && <KeywordPigLoader />}
        {toolsState.tool.status === "initialized" && (
          <>
            <KeywordPigHeader />
            <KeywordPigNavHeader />
            <KeywordPigDesc />
            <KeywordPigCreateDialog
              onClose={handleClose}
              open={toolsState.tool.createDialogOpened}
              defaultNiche={toolsState.tool.defaultSearch}
            />
          </>
        )}
        {toolsState.tool.status === "unauthorized" && <KeywordPigLocked />}
        {/* Show when query is in progress */}
        {toolsState.tool.status === "creating" && (
          <>
            <KeywordPigHeader />
            <KeywordPigNavHeader />
            <KeywordPigLoading />
          </>
        )}
        {toolsState.tool.status === "created" && (
          <>
            <KeywordPigHeader />
            <KeywordPigNavHeader />
            <KeywordPigResults />
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default KeywordPig;
