import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HomeState {
  appName: string;
  apiUrl: string;
  activeMenu: string;
}

const getApiUrl = () => {
  let backend = window.location.origin;
  if (origin === "https://localhost:3000") {
    backend = "https://localhost:3001";
  }
  const url = `${backend}/api`;
  return url;
};

const initialState: HomeState = {
  appName: "wordform-ai",
  apiUrl: getApiUrl(),
  activeMenu: "home",
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },
    setActiveMenu: (state, action: PayloadAction<string>) => {
      state.activeMenu = action.payload;
    },
  },
});

export type { HomeState };
export const { setAppName, setActiveMenu } = homeSlice.actions;
export default homeSlice;

// const dispatch = useAppDispatch()
// dispatch(incrementByAmount(incrementValue))
// dispatch(homeSlice.actions.incrementByAmount(incrementValue))

// export const selectUrl = (state: RootState) => state.home.url
// const url = useAppSelector(selectUrl)

// const url = useSelector((state) => state.home.url)
// dispatch(homeSlice.actions.setUrl(false))
