import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useUnsavedChangesWarning = (
    message: string = 'You have unsaved changes!'
) => {
    const [isDirty, setDirty] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isDirty) {
                const warningText = message;
                event.returnValue = warningText;
                return warningText;
            }
        };

        const handleNavigate = (event: Event) => {
            const customEvent = event as CustomEvent;
            if (isDirty && location.pathname !== customEvent.detail.pathname) {
                const confirmLeave = window.confirm(message);
                if (!confirmLeave) {
                    event.preventDefault();
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('navigate', handleNavigate as EventListener);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('navigate', handleNavigate as EventListener);
        };
    }, [isDirty, message, location.pathname]);

    const onDirty = () => setDirty(true);
    const onPristine = () => setDirty(false);

    return { onDirty, onPristine };
};

export default useUnsavedChangesWarning;
