import CheckmarkIcon from "@mui/icons-material/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import noImageFoundImage from "./no-image-found.svg";

type Props = {
  open: boolean;
  onClose: any;
  onInsertImage: (selected: { url: string; attribution?: string } | null) => void;
  defaultSearchValue?: string;
  initialImage?: AddImageData;
  disableImageProxy?: boolean;
  enableRemoveImage?: boolean;
};

const AddImageTabs = {
  creativeCommons: {
    title: "Browse Creative Commons Library",
    description: "Search for images that are free to use with proper attribution, under a Creative Commons license.",
    text: "Creative Commons",
    searchPlaceholder: "Enter a keyword here...",
    buttonText: "Search",
    showAttributionOption: true,
  },
  dallE: {
    title: "Generate AI Images",
    description: "Generate an AI-generated image automatically with Wordform AI.",
    text: "AI Images",
    searchPlaceholder: `Create a highly detailed image of [subject/concept], designed for [purpose/audience]. Avoid using [specific undesired elements or styles], and emphasize [specific visual characteristics or details, such as color schemes, textures, lighting, perspective, or mood]. The image should evoke [emotion or atmosphere] and include [optional: any specific elements, objects, or scenery].`,
    populate: true,
    type: "textarea",
    buttonText: "Generate",
  },
  byUrl: {
    title: "Add Image By URL",
    description: "Insert an image from the web by URL. Image needs to be publicly accessible.",
    text: "By URL",
    searchPlaceholder: "Enter an image URL here...",
    buttonText: "Insert",
  },
  imageUpload: {
    title: "Upload Image",
    description: "Upload an image directly from your computer (limit 2MB).",
    text: "Upload Image",
    searchPlaceholder: "Enter an image URL here...",
    buttonText: "Insert",
  },
};

type AddImageData = {
  img: string;
  title: string;
  author: string;
  error?: boolean;
  loaded?: boolean;
};

type TabData =
  | {
      title: string;
      description: string;
      text: string;
      searchPlaceholder: string;
      buttonText: string;
      populate?: boolean;
      type?: string;
      showAttributionOption?: boolean;
    }
  | undefined;

export const AddImageDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<TabData>();
  const [search, setSearch] = useState<string>(props.defaultSearchValue || "");
  const [images, setImages] = useState<AddImageData[]>([]);
  const [selected, setSelected] = useState<{
    url: string;
    attribution?: string;
  } | null>(null);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [attributionEnabled, setAttributionEnabled] = useState<boolean>(true);
  const [fileError, setFileError] = useState("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const clearTabState = () => {
    if (props.initialImage) {
      setImages([props.initialImage]);
      setSelected({ url: props.initialImage.img });
      setTab(AddImageTabs.byUrl);
      setSearch(props.initialImage.img);
    } else {
      setImages([]);
      setSelected(null);
      setTab(AddImageTabs.creativeCommons);
      setSearch("");
    }
  };

  const handleTabChange = (e: any, value: any) => {
    setTab(value);
    setImages([]);
    if (value.populate && !search) {
      setSearch(value.searchPlaceholder);
    } else {
      setSearch("");
    }
    debugger;
  };

  //Fix empty tab issue
  useEffect(() => {
    clearTabState();
  }, [props.open]);

  if (!tab) return <></>;

  const handleSearchBarChange = (e: any) => {
    setSearch(e.target.value);
  };

  const getProxyUrl = (url: string) => {
    return props.disableImageProxy ? url : `${apiUrl.replace("/api", "")}/1.0/api/get-image?url=${url}`;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    //Skip search images when adding by url
    if (tab?.text === AddImageTabs.byUrl.text) {
      setImages([{ img: search, title: "", author: "" }]);
      setSelected({
        url: search,
      });
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/article/search-images`, {
        library: tab?.text,
        search,
      });
      const result = response.data.result as AddImageData[];
      const uniqueResults = _.uniqBy(result, "img");
      const proxiedImages = uniqueResults
        .map((item: AddImageData) => {
          item.img = getProxyUrl(item.img);
          return item;
        })
        .slice(0, 30);
      setImages(proxiedImages);

      //Automatically select image if only one exists
      if (proxiedImages.length === 1) {
        let item = proxiedImages[0];
        setSelected({
          url: item.img,
          attribution:
            attributionEnabled && tab.showAttributionOption
              ? `<p style="font-size: 8px; color: gray;"><i>"${item.title}" from <a href="${item.img}" target="_blank">${item.author}</a> and used with no modifications.</i></p>`
              : "",
        });
      }
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating integration");
      }
    }

    setLoading(false);
  };

  const getSearchBar = () => {
    if (tab.title === AddImageTabs.imageUpload.title) {
      return <></>;
    }
    return (
      <form onSubmit={onSubmit}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="search-bar"
            sx={{ marginRight: 1 }}
            onInput={(e) => {}}
            variant="outlined"
            placeholder={tab?.searchPlaceholder}
            size="small"
            value={search}
            onChange={handleSearchBarChange}
            fullWidth
            multiline
            maxRows={4}
            inputProps={{ maxLength: 4000 }}
          />
          <Button variant="text" type="submit" disabled={!search || loading} onClick={onSubmit}>
            {tab?.buttonText}
          </Button>
        </Box>
      </form>
    );
  };

  const blankGrid = !loading ? (
    <>
      <img src={noImageFoundImage} style={{ color: "grey", width: "200px" }} />
      <Typography variant="body1" sx={{ color: "#d6d6d6" }}>
        No images found.
      </Typography>
    </>
  ) : (
    <>
      <CircularProgress />
      <Typography variant="body1" sx={{ my: 4 }}>
        Loading
      </Typography>
    </>
  );

  const handleImageLoaded = (images: AddImageData[]) => {
    const newImages = images.map((item: AddImageData) => {
      item.loaded = true;
      return item;
    });
    setImages(newImages);
  };

  const handleImageError = (images: AddImageData[], required?: boolean) => {
    if (required) toast.error("Error loading image!");
    const newImages = images.map((item: AddImageData) => {
      item.loaded = true;
      item.error = true;
      return item;
    });
    setImages(newImages);
  };

  const getImageMultiselect = (images: AddImageData[]) => {
    const allImagesLoaded = images.every((image) => image.loaded);
    return (
      <Box sx={{ padding: "10px" }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {images.map((item: AddImageData) => {
            const isSelected = selected && item.img === selected.url;

            const handleSelectImage = () => {
              !isSelected
                ? setSelected({
                    url: item.img,
                    attribution:
                      attributionEnabled && tab.showAttributionOption
                        ? `<p style="font-size: 8px; color: gray;"><i>"${item.title}" from <a href="${item.img}" target="_blank">${item.author}</a> and used with no modifications.</i></p>`
                        : "",
                  })
                : setSelected(null);
            };

            return (
              <ImageListItem
                key={item.img}
                onClick={handleSelectImage}
                sx={{
                  border: (theme) => (isSelected ? "3px solid " + theme.palette.primary.main : "none"),
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <ImageListItemBar
                  sx={{
                    background: isSelected
                      ? "linear-gradient(to bottom, rgba(0,0,0,0) 0%, " + "rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.8) 100%)"
                      : "linear-gradient(to bottom, rgba(0,0,0,0) 0%, " + "rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.8) 100%)",
                  }}
                  title={item.title}
                  position="bottom"
                  actionPosition="left"
                />
                {isSelected && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: "rgba(128, 128, 128, 0.3)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckmarkIcon
                      style={{
                        color: "white",
                        fontSize: "2rem",
                      }}
                    />
                  </div>
                )}
                <img
                  src={`${item.img}`}
                  alt={item.title}
                  draggable="false"
                  style={{
                    userSelect: "none",
                    opacity: allImagesLoaded ? "1" : "0",
                  }}
                  loading="eager"
                  onError={() => handleImageError(images)}
                  onLoad={() => handleImageLoaded(images)}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  };

  const getImageSingle = (item: AddImageData) => {
    return (
      <Box sx={{ padding: "10px" }}>
        <ImageList cols={1} gap={8}>
          <ImageListItem
            key={item.img}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              padding: "20px",
              background: (theme) => alpha(theme.palette.grey[100], 0.6),
            }}
          >
            <img
              srcSet={`${images[0].img}`}
              src={`${images[0].img}`}
              alt={images[0].title}
              draggable="false"
              style={{
                userSelect: "none",
                maxWidth: "400px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              onError={() => handleImageError(images, true)}
              onLoad={() => handleImageLoaded(images)}
            />
          </ImageListItem>
        </ImageList>
      </Box>
    );
  };

  const handleInsert = async () => {
    if (selected) props.onInsertImage(selected);
    props.onClose();
  };

  const handleFileChange = async (event: any) => {
    setFileError("");
    setUploadProgress(0);
    setImages([]);
    const file = event.target.files[0];
    if (!file) {
      setFileError("No image was uploaded.");
      return;
    }

    // Check if the file size exceeds a certain limit (e.g., 2MB)
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSizeInBytes) {
      setFileError("Image must be less than 2mb.");
      return;
    }

    //Handle file upload
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const response = await axios.post(`${apiUrl}/article/upload-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      const result = response.data.result;
      setImages([
        {
          img: getProxyUrl(result.url),
          title: "New Image",
          author: "New Author",
        },
      ]);
      setSelected({ url: getProxyUrl(result.url) });
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        setFileError("Error: " + e.response.data.error.message);
      } else if (e.request) {
        setFileError("Network error occurred...");
      } else {
        setFileError("Error creating integration");
      }
    }
  };

  const getFileUpload = () => {
    if (tab.title === AddImageTabs.imageUpload.title) {
      if (uploadProgress > 1 && uploadProgress < 100 && !fileError) {
        return (
          <>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </>
        );
      }
      return (
        <>
          <TextField
            type="file"
            inputProps={{
              accept: "image/*",
            }}
            onChange={handleFileChange}
            sx={{ mb: 2 }}
          />
          <>{fileError && <FormHelperText error>{fileError}</FormHelperText>}</>
        </>
      );
    }
  };

  const getTabContent = () => {
    if (images && images.length === 1) {
      return getImageSingle(images[0]);
    } else if (images && images.length > 1) {
      return getImageMultiselect(images);
    }
    return (
      <Box
        sx={{
          minHeight: "320px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {blankGrid}
      </Box>
    );
  };

  const handleToggleAttributionOption = () => {
    setAttributionEnabled(!attributionEnabled);
  };

  const getAttributionOption = () => {
    if (tab.showAttributionOption) {
      return (
        <FormControlLabel
          sx={{ my: "5px" }}
          control={<Checkbox checked={attributionEnabled} onClick={handleToggleAttributionOption} />}
          label={"Automatically Insert Creative Commons Attribution"}
        />
      );
    }
    return <></>;
  };

  const handleRemoveImage = () => {
    props.onClose();
    props.onInsertImage(null);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2 }}>
        <Tabs value={tab} onChange={handleTabChange} centered aria-label="Add Image">
          <Tab label={AddImageTabs.creativeCommons.text} value={AddImageTabs.creativeCommons} />
          <Tab label={AddImageTabs.dallE.text} value={AddImageTabs.dallE} />
          <Tab label={AddImageTabs.byUrl.text} value={AddImageTabs.byUrl} />
          <Tab label={AddImageTabs.imageUpload.text} value={AddImageTabs.imageUpload} />
        </Tabs>
      </Box>
      <DialogTitle>
        {tab?.title}
        <DialogContentText>{tab?.description}</DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Box>{getSearchBar()}</Box>
        <Box>{getFileUpload()}</Box>
        <Box>{getTabContent()}</Box>
      </DialogContent>
      <DialogActions>
        <Box>{getAttributionOption()}</Box>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ width: "100%" }}>
          {selected && (
            <LoadingButton variant="text" color="error" onClick={handleRemoveImage}>
              Remove Image
            </LoadingButton>
          )}

          {/* This Box acts as a spacer pushing the buttons to each end */}
          <Box sx={{ flexGrow: 1 }}></Box>

          <Stack direction="row" spacing={1}>
            <LoadingButton variant="text" onClick={props.onClose}>
              Close
            </LoadingButton>
            <LoadingButton variant="contained" onClick={handleInsert} disabled={!selected}>
              Insert Image
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
