import {
  Container
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

const KeywordPigLocked = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationData = useSelector((state: RootState) => state.integrations);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      ></Container>
    </>
  );
};

export default KeywordPigLocked;
