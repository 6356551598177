import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/store";
import { BillingResponse } from "../../../interfaces/billing.interface";
import { CreditResponse } from "../../../interfaces/credit.interface";
import {
  PlanResponse,
  SubscriptionResponse,
  ToolsResponse,
} from "../../../interfaces/subscriptions.interface";
import { UserResponse } from "../../../interfaces/user.interface";

interface AuthState {
  user?: UserResponse;
  credits?: CreditResponse;
  imageLimit?: CreditResponse;
  billing?: BillingResponse;
  subscriptions?: SubscriptionResponse;
  plan?: PlanResponse;
  tools?: ToolsResponse;
}

const initialState: AuthState = {};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setCredits: (state, action: PayloadAction<any>) => {
      state.credits = action.payload;
    },
    setImageLimit: (state, action: PayloadAction<any>) => {
      state.imageLimit = action.payload;
    },
    setBilling: (state, action: PayloadAction<any>) => {
      state.billing = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<any>) => {
      state.subscriptions = action.payload;
    },
    setPlan: (state, action: PayloadAction<any>) => {
      state.plan = action.payload;
    },
    setTools: (state, action: PayloadAction<any>) => {
      state.tools = action.payload;
    },
  },
});

export type { AuthState };
export const { setUser } = authSlice.actions;
export const user = (state: RootState) => state.auth.user;
export default authSlice;
