import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import { KeywordJobData } from "../keyword-pig.slice";

const KeywordPigResultsTable = (props: { hideButtons?: boolean }) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const toolData = useSelector((state: RootState) => state.keywordPig);

  const results = toolData.tool.data
    ? (toolData.tool.data as KeywordJobData).data.keywords
    : null;

  if (!results) {
    return <></>;
  }

  const getCircularProgressBar = (num: number) => {
    const getColor = (num: number): string => {
      if (num > 0 && num < 3) {
        return "#d93452";
      } else if (num >= 4 && num < 7) {
        return "#fa704e";
      } else if (num >= 7 && num < 8) {
        return "#fdc509";
      } else {
        return "#34c176";
      }
    };
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={Math.ceil((num / 10) * 100)}
          sx={{ color: getColor(num) }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color={"black"}>
            {num.toFixed(1)}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Stack>
      <TableContainer>
        <Table sx={{ width: 1000 }} aria-label="results table">
          <TableHead>
            <TableRow>
              <TableCell>Keywords</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {[...results].map((result) => {
              return (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {result}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter sx={{ my: 4 }}></TableFooter>
    </Stack>
  );
};

export default KeywordPigResultsTable;
