import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import Logo from "../../logo/logo";

const TOP_NAV_HEIGHT = 64;
const SUPPORT_EMAIL = "support@wordform.ai";

export const AuthLayout = (props: any) => {
  const { children } = props;

  return (
    <>
      <Box
        component="header"
        sx={{
          backgroundColor: "background.paper",
          position: "sticky",
        }}
      >
        <Container maxWidth="md" sx={{ height: TOP_NAV_HEIGHT }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                height: 24,
                width: 24,
              }}
            >
              <Logo />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        component="main"
        sx={{
          backgroundColor: "background.paper",
          flexGrow: 1,
          py: "64px",
        }}
      >
        <Container maxWidth="sm">
          {children}
          <Divider
            sx={{
              mb: 3,
              mt: 6,
            }}
          />
          <Stack alignItems="center" spacing={2}>
            <Typography align="center" variant="h5">
              Contact Our Support Team
            </Typography>
            <Typography align="center" color="text.secondary" variant="body2">
              If you have any questions or concerns, please contact our support
              team Monday through Friday (9 am - 5 pm EST) by sending an email
              to <b>{SUPPORT_EMAIL}</b>
            </Typography>
            <Stack alignItems="center" direction="row" spacing={4}></Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AuthLayout;
