import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import ArticleIcon from "@mui/icons-material/Article";
import NotesIcon from "@mui/icons-material/Notes";
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { RichTextEditorRef } from "mui-tiptap";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editorSlice } from "..";
import { Loader } from "../../../common/components/loader";
import { OverlayLoader } from "../../../common/components/overlay-loader";
import EditorLayout from "../../../common/layouts/editor";
import { PanelButton } from "../../../common/layouts/editor/panel-button";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { IntegrationResponse } from "../../../interfaces/integration.interface";
import ContentEditor from "../editor/content-editor";
import { Panel } from "../panel/panel";
import useProject from "../panel/use-project";
import { SideNav } from "../sideNav/side-nav";
import useUnsavedChangesWarning from "../use-unsaved-changes";
import { EditorDialogs } from "./editor-dialogs";
import FeaturedImage from "./featured-image";

const Editor = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const authState = useSelector((state: RootState) => state.auth);
  const transitionLoading = useSelector((state: RootState) => state.editor.transitionLoading);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));

  const loading = useSelector((state: RootState) => state.editor.loading) as boolean;
  const keyword = useSelector((state: RootState) => state.editor.article?.keyword);
  const generating = useSelector((state: RootState) => state.editor.article?.generating);
  const editorOpened = useSelector((state: RootState) => state.editor.editorOpened);
  const editorTabArticleDisabled = useSelector((state: RootState) => state.editor.editorTabArticleDisabled);
  const integrationId = useSelector((state: RootState) => state.editor.project?.integrationId);
  const featuredImage = useSelector((state: RootState) => state.editor.article?.featuredImage);
  const editorRef = useRef<RichTextEditorRef>(null);
  const project = useProject(editorRef);
  const { onDirty, onPristine } = useUnsavedChangesWarning();

  useEffect(() => {
    document.title = `Wordform AI - ${keyword || "New Article"}`;
  }, [keyword]);

  const handleKeywordEditButtonClick = () => {
    dispatch(editorSlice.actions.setUpdateArticleDialogOpened(true));
  };

  const handleEditorOpenButtonGroup = (e: React.MouseEvent<HTMLElement>, editorOpened: "outline" | "article") => {
    dispatch(editorSlice.actions.setEditorOpened(editorOpened || "outline"));
  };

  const [integration, setIntegration] = useState<IntegrationResponse | null>(null);

  const getIntegration = async (data: { id: string }) => {
    try {
      const response: any = await axios.post(`${apiUrl}/integration/get`, { id: data.id, validate: false });
      const result: IntegrationResponse = response.data.result;
      setIntegration(result);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
  };

  useEffect(() => {
    onDirty();
    if (id) {
      project.refresh(id);
      dispatch(editorSlice.actions.setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (integrationId) {
      getIntegration({ id: integrationId });
    } else {
      setIntegration(null);
    }
  }, [integrationId]);

  if (loading || !authState.user || !authState.user.id || !id) {
    return <Loader />;
  }

  return (
    <>
      <EditorLayout>
        <SideNav editorRef={editorRef} />
        <Panel editorRef={editorRef} />
        {mdDown && (
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 1, mb: 3 }}>
            <PanelButton editorRef={editorRef} />
          </Stack>
        )}
        <Stack sx={{ my: "10px", ml: "3px" }} direction="row" alignItems="center" spacing={1}>
          <TextField
            placeholder="Keyword"
            variant="filled"
            sx={{ width: "100%", caretColor: "transparent" }}
            inputProps={{ style: { fontSize: 20 } }}
            value={keyword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleKeywordEditButtonClick}
                    disabled={generating}
                    color="secondary"
                    startIcon={<PencilIcon style={{ height: "0.8em", paddingRight: "2px" }} />}
                  >
                    Edit
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction={lgDown ? "column" : "row"} alignItems="center" justifyContent="space-between">
          <FeaturedImage featuredImage={featuredImage} disabled={generating || editorOpened === "outline"} />
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={editorOpened || "outline"}
            exclusive
            onChange={handleEditorOpenButtonGroup}
            disabled={generating}
          >
            <ToggleButton value="outline">
              <NotesIcon sx={{ px: "3px" }} />
              Outline
            </ToggleButton>
            <ToggleButton value="article" disabled={editorTabArticleDisabled}>
              <ArticleIcon sx={{ px: "3px" }} />
              Article
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <ContentEditor editorRef={editorRef} />

        <EditorDialogs editorRef={editorRef} id={id} />
      </EditorLayout>
    </>
  );
};

export default Editor;
