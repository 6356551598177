import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const initialValues = {
  customerEmail: "",
  customerName: "",
  submit: null,
};

type Props = {
  open: boolean;
  onClose: any;
  newPKS: boolean;
  firstName: string;
  lastName: string;
  email: string;
};

const BILLING_EMAIL = "support@wordform.ai";

export const ChangeBillingDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    //   }}
    >
      <DialogTitle>Change Subscription Plan</DialogTitle>
      <DialogContent>
        To upgrade your plan, please send a written request to{" "}
        <b>{BILLING_EMAIL} </b>
        and we will process your upgrade within 24-48 hours.
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
        <Button href={`mailto:${BILLING_EMAIL}`} variant="contained">
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};
