import PlusIcon from "@mui/icons-material/Add";
import {
  Button,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { CreateProjectDialog } from "./create-project-dialog";
import projectsSlice from "./projects.slice";

const ProjectsHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const projectsState = useSelector((state: RootState) => state.projects);

  const handleOpen = () => {
    dispatch(projectsSlice.actions.setCreateDialogOpened(true));
  };

  const handleClose = () => {
    dispatch(projectsSlice.actions.setCreateDialogOpened(false));
  };

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h4">Projects</Typography>
        <Button
          onClick={handleOpen}
          size="large"
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
        >
          Create
        </Button>
        <CreateProjectDialog
          onClose={handleClose}
          open={projectsState.createDialogOpened}
        />
      </Stack>
    </>
  );
};

export default ProjectsHeader;
