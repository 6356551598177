import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  SvgIcon,
} from "@mui/material";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";

type Item = {
  name: string;
  subitems?: Array<SubItem>;
  opened: boolean;
  selected: boolean;
};

type SubItem = {
  name: string;
};

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
};

//Extract items from content
const extractItemsFromHTML = (htmlString: string): Array<Item> => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const nodes = Array.from(doc.body.childNodes);

  const items: Array<Item> = [];

  let currentItem: Item | null = null;

  nodes.forEach((node) => {
    if (node.nodeName === "H2") {
      if (currentItem) {
        items.push(currentItem);
      }

      currentItem = {
        name: node.textContent || "",
        subitems: [],
        opened: false,
        selected: false,
      };
    } else if (node.nodeName === "H3" && currentItem) {
      if (currentItem.subitems)
        currentItem.subitems.push({ name: node.textContent || "" });
    }
  });

  if (currentItem) {
    items.push(currentItem);
  }

  return items;
};

export const Outline = (props: Props) => {
  const editorState = useSelector((state: RootState) => state.editor);
  const editor = props.editorRef.current?.editor;
  const editorHTML = editor?.getHTML() || "";
  const [items, setItems] = useState<Array<Item>>(
    extractItemsFromHTML(editorState.localArticle?.content || "")
  );

  useEffect(() => {
    setItems(extractItemsFromHTML(editorState.localArticle?.content || ""));
  }, [editorState.localArticle]);

  const setOpened = (name: string, opened: boolean) => {
    setItems(
      items.map((item) => {
        if (item.name == name) {
          item.opened = opened;
          return item;
        }
        return item;
      })
    );
  };

  //Hacky, but having trouble figuring out how to do this with prosemirror
  const scrollToHeading = (headingType: "h2" | "h3", text: string) => {
    const editorEl = document.querySelector(".ProseMirror"); // Adjust based on your editor's DOM structure

    if (editorEl) {
      const targetHeading = Array.from(
        editorEl.querySelectorAll(headingType)
      ).find((heading: Element) => heading.textContent === text);

      if (targetHeading) {
        const targetBounding = targetHeading.getBoundingClientRect();
        const offsetY = 100;
        window.scrollTo({
          top: window.scrollY + targetBounding.top - offsetY,
          behavior: "smooth",
        });

        // Highlight text in the heading
        const range = document.createRange();
        range.selectNodeContents(targetHeading);

        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges(); // clear current selection
          selection.addRange(range);
        }
      }
    }
  };

  return (
    <List
      sx={{ width: "100%", height: "100%", py: "10px" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader sx={{ fontWeight: "bold" }}>
          Article Outline
        </ListSubheader>
      }
    >
      {items.map((item, k) => {
        let subitems = item.subitems as Array<Item> | undefined;
        return (
          <div key={k}>
            <ListItemButton
              onClick={() => {
                setOpened(item.name, !item.opened);
                scrollToHeading("h2", item.name);
              }}
            >
              <ListItemText secondary={item.name} />
              <SvgIcon fontSize="small">
                {subitems && item.opened && <ChevronLeftIcon />}
                {subitems && !item.opened && <ChevronRightIcon />}
              </SvgIcon>
            </ListItemButton>
            {subitems &&
              item.opened &&
              subitems?.map((subitem) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      scrollToHeading("h3", subitem.name);
                    }}
                  >
                    <ListItemText secondary={subitem.name} />
                  </ListItemButton>
                );
              })}
          </div>
        );
      })}
    </List>
  );
};

export default Outline;
