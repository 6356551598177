import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationResponse } from "../../interfaces/integration.interface";

interface IntegrationsState {
  integrations: Array<IntegrationResponse>;
  integrationsCount: number;
  page: number;
}

const initialState: IntegrationsState = {
  integrations: [],
  integrationsCount: 0,
  page: 1,
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setIntegrations: (
      state,
      action: PayloadAction<Array<IntegrationResponse>>
    ) => {
      state.integrations = action.payload;
    },
    setIntegrationsCount: (state, action: PayloadAction<number>) => {
      state.integrationsCount = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export type { IntegrationsState };
export const {} = integrationsSlice.actions;
export default integrationsSlice;
