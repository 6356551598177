import { blue, green, indigo, purple, red } from "./colors";

export const getPrimary = (preset) => {
  switch (preset) {
    case "blue":
      return blue;
    case "green":
      return green;
    case "red":
      return red;
    case "indigo":
      return indigo;
    case "purple":
      return purple;
    default:
      return indigo;
  }
};
