import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import generateArticleImage from "./publish-now.png";

type Props = {
  open: boolean;
  onClose: any;
};

export const PublishInfoDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <img
        src={generateArticleImage}
        width="200px"
        style={{ alignSelf: "center", marginTop: "25px", marginBottom: "0" }}
      />
      <DialogTitle>
        Final Step - Publish the Article
        <DialogContentText>
          Your article has been generated! Here's the next step...
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        Edit your article to make it perfect. Then, once you're done, click the
        "Publish" button at the top to post it on your WordPress site.
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" onClick={props.onClose}>
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
