import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import { Box, Button, Divider, Drawer, Stack, SvgIcon, Typography, useMediaQuery } from "@mui/material";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Scrollbar } from "../../../common/components/scrollbar";
import Outline from "../../../common/layouts/editor/outline";
import UsageBar from "../../../common/layouts/editor/usage-bar";
import { RootState } from "../../../core/store/store";

const SIDE_NAV_WIDTH = 270;
const SIDE_NAV_COLLAPSED_WIDTH = 73; // icon size + padding + border right

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
};

export const SideNav = (props: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const collapse = false;
  const authState = useSelector((state: RootState) => state.auth);
  const editorSaving = useSelector((state: RootState) => state.editor.editorSaving);

  const creditsUsed = authState.credits?.used || 0;
  const creditsAvailable = authState.credits?.available || 0;
  const imagesUsed = authState.imageLimit?.used || 0;
  const imagesAvailable = authState.imageLimit?.available || 0;

  const navigate = useNavigate();
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  if (mdDown) return <></>;

  return (
    <Drawer
      open
      variant="permanent"
      PaperProps={{
        onMouseEnter: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: "background.default",
          height: `calc(100% - 20px)`,
          overflowX: "hidden",
          top: "20px",
          px: "10px",
          py: "10px",
          transition: "width 250ms ease-in-out",
          width: collapse ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
          zIndex: (theme) => theme.zIndex.appBar - 100,
        },
      }}
    >
      <Box
        onClick={() => {
          if (editorSaving) {
            const confirmLeave = window.confirm("You have unsaved changes! Do you really want to leave?");
            if (confirmLeave) navigate("/");
          } else {
            navigate("/");
          }
        }}
      >
        <Box>
          <Button
            size="medium"
            sx={{ px: "20px", py: "6px" }}
            startIcon={
              <SvgIcon fontSize="small">
                <ChevronLeftIcon />
              </SvgIcon>
            }
            variant="text"
            color="secondary"
          >
            Back
          </Button>
        </Box>
        {/* <Box sx={{ padding: "20px 20px 0 20px" }}>
          <Logo width="180px" />
        </Box> */}
        <Stack alignItems="center" direction="row" spacing={1} sx={{ mt: "20px" }}></Stack>
      </Box>

      <Scrollbar
        sx={{
          height: "100%",
          overflowX: "hidden",
          "& .simplebar-content": {
            height: "100%",
          },
        }}
      >
        {props.editorRef.current?.editor && <Outline editorRef={props.editorRef} />}
      </Scrollbar>
      <Box
        component="nav"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box
          component="ul"
          sx={{
            flexGrow: 1,
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        ></Box>

        <Divider />
        <Box sx={{ pt: 1 }}>
          <UsageBar label="Article Credits Used" used={creditsUsed} available={creditsAvailable} />
          {imagesAvailable > 0 && <UsageBar label="Image Credits Used" used={imagesUsed} available={imagesAvailable} />}
          <Divider sx={{ pt: 1 }} />
          <Box sx={{ pt: 0.5, display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="text.secondary" fontWeight="bold">
              {authState.plan?.selected ? authState.plan?.selected.name : "No subscription"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
