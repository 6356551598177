import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@wordform/core/hooks/useStore";
import { RootState } from "@wordform/core/store/store";
import paddleSlice from "./paddle.slice";
import { PaddleSubscription, PaddleAPIResponse } from "./paddle.slice";

type Props = {
  sub: PaddleSubscription;
  open: boolean;
  onClose: any;
};

export const CancelSubscriptionDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const cancelSubscription = async (subscriptionId: string, priceId: string) => {
    try {
      setLoading(true);
      const response = await axios.delete(`${apiUrl}/paddle`, {
        data: { subscriptionId: subscriptionId, priceId: priceId },
      });
      const result: PaddleAPIResponse = response.data.result;
      dispatch(paddleSlice.actions.setPaddlePlans(result));
      props.onClose();

      toast.success("Successfully cancelled subscription!");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error cancelling subscription...");
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>Confirm Cancellation</DialogTitle>
      <DialogContent>
        Are you sure you want to cancel <strong>{props.sub.name}</strong>?
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Dismiss
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => cancelSubscription(props.sub.paddleSubscriptionId, props.sub.priceId)}
          variant="contained"
        >
          Cancel Now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
