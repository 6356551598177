import {
  Alert,
  Stack,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";

const ToolsHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const projectsState = useSelector((state: RootState) => state.projects);

  return (
    <>
      <Alert color="info" severity="info">
        Introducing Tools - Access Helpful Content Creation Tools All In One
        Place...
      </Alert>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h4">Tools</Typography>
      </Stack>
    </>
  );
};

export default ToolsHeader;
