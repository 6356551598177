import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, Stack, SvgIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import FilterIntegrations from "./filter-integrations";
import projectsSlice from "./projects.slice";
import SearchProjects from "./search-projects";

const ProjectsFilter = () => {
  const dispatch = useAppDispatch();
  const filter = useSelector((state: RootState) => state.projects.filter);
  const handleClearFilter = () => {
    dispatch(projectsSlice.actions.resetFilter());
  };
  return (
    <>
      <Card
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          width: "100%",
          px: 1,
          py: 1,
          mt: 2,
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            py: 1,
          }}
        >
          <Stack spacing={3} direction="row">
            <SearchProjects />
            <FilterIntegrations />
          </Stack>
          <Box>
            {filter.filtered && (
              <Button
                variant="text"
                startIcon={
                  <SvgIcon fontSize="small">
                    <CloseIcon />
                  </SvgIcon>
                }
                onClick={handleClearFilter}
              >
                Clear
              </Button>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ProjectsFilter;
