import { Box, Button, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import AuthLayout from "../../../common/layouts/auth";
import { RootState } from "../../../core/store/store";

type Inputs = {
  recoveryCode: string;
  password: string;
  passwordAgain: string;
};

const Recover = () => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
    formState,
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const { email, code } = useParams<{ email: string; code: string }>();

  useEffect(() => {
    if (code) {
      setValue("recoveryCode", code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await axios.post(`${apiUrl}/user/reset-update-pass`, {
        email: email?.trim(),
        recoveryCode: data.recoveryCode.trim(),
        password: data.password.trim(),
      });
      toast.success("Password updated!");
      navigate("/login");
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.error("Network error occurred...");
      } else {
        toast.error("Error resetting password...");
      }
    }
    reset();
    setLoading(false);
  };

  return (
    <AuthLayout>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            mb: 3,
          }}
        >
          <Typography variant="h4">Set a New Password</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button href="/login">Login</Button>
        </Box>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Enter Your Recovery Code"
            type="number"
            {...register("recoveryCode", {
              required: true,
              minLength: 4,
            })}
          />
          {errors.recoveryCode && <FormHelperText error>Please enter a valid recovery code!</FormHelperText>}
          <TextField
            fullWidth
            label="Password"
            type="password"
            error={errors.password != null}
            {...register("password", { required: true, minLength: 5 })}
          />
          {errors.password && (
            <FormHelperText error={errors.password != null}>Please enter a valid password!</FormHelperText>
          )}
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            error={errors.passwordAgain != null}
            {...register("passwordAgain", {
              required: "Please confirm password!",
              minLength: 5,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value;
                },
              },
            })}
          />
          {errors.passwordAgain && (
            <FormHelperText error={errors.passwordAgain != null}>Your password does not match!</FormHelperText>
          )}
        </Stack>
        <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
          Reset password
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Recover;
