import "./instrument";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app";
import { persistor, store } from "./core/store/store";
import "./index.scss";

// Routing based on
// https://github.com/remix-run/react-router/blob/main/docs/getting-started/installation.md#basic-installation

// Redux toolkit persist based on
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist

const container = document.getElementById("root") ?? document.createElement("div");
const root = ReactDOM.createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred, developers have been notified.</p>}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);
