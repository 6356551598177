import axios from "axios";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { useDebounce } from "../../../hooks/use-debounce";
import { ArticleResponse } from "../../../interfaces/article.interface";
import { ProjectResponse } from "../../../interfaces/projects.interface";
import editorSlice from "../editor/editor.slice";
import useGenerateArticle from "../editor/use-generate-article";

const useProject = <T>(editorRef: RefObject<RichTextEditorRef>) => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const generateArticle = useGenerateArticle(editorRef);

  const dispatch = useAppDispatch();
  const getProject = async (projectId: string) => {
    try {
      const response: any = await axios.post(`${apiUrl}/project/get`, {
        id: projectId,
      });
      const result = response.data.result as ProjectResponse;
      dispatch(editorSlice.actions.setProject(result));

      if (result.articleId) {
        await getArticle(result.articleId);
      }
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting project...");
      }
    }
  };

  const getArticle = async (articleId: string) => {
    try {
      dispatch(editorSlice.actions.setLoading(true));
      const response: any = await axios.post(`${apiUrl}/article/get`, {
        id: articleId,
      });
      const result = response.data.result as ArticleResponse;
      dispatch(editorSlice.actions.setArticle(result));
      dispatch(editorSlice.actions.setLoading(false));
      if (result.generating) {
        generateArticle.runFetchGenerationLoopDebounced(
          result.id,
          result.status === "creating_outline" ? "outline" : "article"
        );
      } else {
        editorRef.current?.editor
          ?.chain()
          .setContent(response.data.result.content)
          .run();
      }
      return result.generating;
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting article...");
      }
    }
  };

  const handleSave = async (data: {
    id: string;
    content?: string;
    outline?: string;
  }) => {
    dispatch(editorSlice.actions.setEditorSaving(true));
    try {
      const response = await axios.post(`${apiUrl}/article/update`, {
        id: data.id,
        content: data.content,
        outline: data.outline,
      });
      const article = response.data.result;

      dispatch(editorSlice.actions.setArticle(article));
      dispatch(editorSlice.actions.setEditorSaving(false));
      dispatch(editorSlice.actions.setEditorEditable(true));
      if (data.outline) {
        dispatch(editorSlice.actions.setEditorOpened("outline"));
      }
    } catch (e: any) {
      dispatch(editorSlice.actions.setEditorEditable(false));
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error updating article");
      }
    }
  };

  const debounceHandleSave = useDebounce({ callback: handleSave });

  const refresh = async (projectId: string) => {
    dispatch(editorSlice.actions.reset());
    getProject(projectId);
  };

  return { refresh, debounceHandleSave, handleSave };
};

export default useProject;
