import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { editorSlice } from "..";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { integrationsSlice } from "../../integrations";

type Props = {
  open: boolean;
  onClose: any;
  handleCreateIntegration: any;
  update?: boolean;
};

type UpdateIntegrationData = {
  integrationId?: string;
};

export const UpdateIntegrationDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);
  const integrationState = useSelector((state: RootState) => state.integrations);
  const [createIntegrationDialogOpened, setCreateIntegrationDialogOpened] = useState<boolean>(false);

  const getIntegrations = async () => {
    try {
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
    setValue,
  } = useForm<UpdateIntegrationData>({
    mode: "onChange",
  });

  useEffect(() => {
    setValue("integrationId", editorState.project?.integrationId || ("" as string));
  }, [props]);

  const refreshData = async () => {
    setLoading(true);
    const response = await getIntegrations();
    setLoading(false);
  };
  useEffect(() => {
    if (props.open) {
      refreshData();
    }
  }, [editorState.article?.id]);

  const updateArticleStatus = async (data: UpdateIntegrationData) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/article/update-integration`, {
        id: editorState.article?.id,
        integrationId: data.integrationId,
      });
      toast.success(`Successfully updated integration`);
      dispatch(editorSlice.actions.setArticle(response.data.result));
      props.onClose();
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
    }
  };

  const updateProject = async (data: UpdateIntegrationData) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/project/update`, {
        id: editorState.project?.id,
        integrationId: data.integrationId ? data.integrationId : null,
      });
      dispatch(editorSlice.actions.setProject(response.data.result));
      if (!props.update) {
        updateArticleStatus(data);
        reset();
      } else {
        toast.success(`Successfully updated integration`);
        props.onClose();
        reset();
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error updating integration");
      }
    }
  };

  const onSubmit: SubmitHandler<UpdateIntegrationData> = async (data) => {
    updateProject(data);
  };

  const handleSkip = () => {
    updateProject(getValues());
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Update the Integration
          <DialogContentText>
            <Typography variant="body1">Automatically integrate your website with Wordform AI. </Typography>
            <br />
            <Typography variant="body2">
              Wordform AI will automatically integrate with your website, making it easy to post content in one click
              and interlink between other posts.
            </Typography>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              select
              label="Integration"
              {...register("integrationId", {})}
              defaultValue={getValues("integrationId") || ""}
              disabled={loading}
            >
              <MenuItem value={"createIntegration"} onClick={props.handleCreateIntegration}>
                Create a New Integration...
              </MenuItem>
              <MenuItem key={""} value={""}>
                No Integration
              </MenuItem>
              {integrationState.integrations &&
                integrationState.integrations.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.data.domain}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={props.update ? props.onClose : handleSkip} disabled={loading}>
            {props.update ? "Cancel" : "Skip"}
          </Button>
          <LoadingButton loading={loading} type="submit" variant="contained" disabled={!formState.isValid}>
            Update Now
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
