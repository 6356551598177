import { Box, Button, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NotFoundIcon from "./illustration-not-found.svg";

type props = {
  status: number;
  message: string;
};

const Error = (props: props) => {
  const theme = useSelector((state: any) => state.home.theme);

  return (
    <>
      {/* <Head>
        <title>
          Error: Not Found | Material Kit Pro
        </title>
      </Head> */}
      <Box
        sx={{
          backgroundColor: "background.paper",
          flexGrow: 1,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            px: 5,
            py: 14,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              "& img": {
                maxWidth: "100%",
              },
            }}
          >
            <img src={NotFoundIcon} />
          </Box>
          <Typography align="center" sx={{ my: 2 }} variant="h3">
            Nothing here!
          </Typography>
          <Typography align="center" color="text.secondary" variant="body2">
            The page requested does not exist.
          </Typography>
          <Button href={"/"} sx={{ mt: 2 }}>
            Take me home
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default Error;
