import { useCallback, useState } from "react";

export function useDialog() {
  const [state, setState] = useState({
    open: false,
    data: {} as any | undefined,
  });

  const handleOpen = useCallback((data?: any) => {
    setState({
      open: true,
      data,
    });
  }, []);

  const handleClose = useCallback(() => {
    setState({
      open: false,
      data: {},
    });
  }, []);

  return {
    data: state.data,
    handleClose,
    handleOpen,
    open: state.open,
  };
}
