import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { error, info, neutral, success, warning } from "../colors";
import { getPrimary } from "../utils";

export const createPalette = (config) => {
  const { colorPreset } = config;

  return {
    action: {
      active: neutral[400],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: "#FCFCFD",
      paper: common.white,
    },
    divider: "#F2F4F7",
    error,
    info,
    mode: "light",
    neutral,
    primary: getPrimary(colorPreset),
    secondary: {
      main: "#424242", // example dark gray color
      light: "#6D6D6D", // lighter shade of gray
      dark: "#1B1B1B", // darker shade of gray
      contrastText: "#FFFFFF", // text color that contrasts with dark gray
    },
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
};
