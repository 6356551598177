import { Checkbox, FormControlLabel, FormHelperText, MenuItem, TextField } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Loader } from "../../../common/components/loader";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { TemplateSettingValue } from "../../../interfaces/projects.interface";
import { integrationsSlice } from "../../integrations";

type Props = {
  value: { [key: string]: any };
  onChange?: (value: { [key: string]: any }) => void;
};

export const PanelSettingsTemplate = (props: Props) => {
  const editorState = useSelector((state: RootState) => state.editor);
  const integrationState = useSelector((state: RootState) => state.integrations);
  const settingsData = editorState.project?.settingsData;
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const setValue = (name: string, value: any) => {
    const v = _.cloneDeep(props.value);
    v[name] = value;
    if (props.onChange) props.onChange(v);
  };

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getIntegrations();
  }, [editorState.article?.id]);

  const getSetting = (key: string, setting: TemplateSettingValue) => {
    //If showWhen is set, check if the value matches the condition
    if (
      setting.showWhen &&
      Object.keys(setting.showWhen).filter((key) => props.value[key] != (setting.showWhen as any)[key]).length > 0
    )
      return <></>;

    if (props.value[key] == undefined) {
      setValue(key, setting.default);
    }

    switch (setting.type) {
      case "checkmark":
        return (
          <div key={key}>
            <FormControlLabel
              sx={{ my: "5px" }}
              control={
                <Checkbox
                  checked={props.value[key] == true}
                  onClick={() => {
                    setValue(key, !props.value[key]);
                  }}
                />
              }
              label={setting.name}
            />
            <FormHelperText sx={{ marginTop: "0px !important" }}>{setting.desc}</FormHelperText>
          </div>
        );
      case "dropdown":
        return (
          <TextField
            fullWidth
            select
            label={setting.name}
            onChange={(e) => {
              setValue(key, e.target.value);
            }}
            key={key}
          />
        );
      case "text":
        return (
          <TextField
            fullWidth
            value={props.value[key]}
            label={setting.name}
            helperText={setting.desc}
            onChange={(e) => {
              setValue(key, e.target.value);
            }}
            key={key}
          ></TextField>
        );
      case "number":
        return (
          <TextField
            fullWidth
            type="number"
            value={props.value[key]}
            label={setting.name}
            helperText={setting.desc}
            onChange={(e) => {
              setValue(key, e.target.value);
            }}
            key={key}
          ></TextField>
        );
      case "integration":
        return (
          <TextField
            fullWidth
            select
            label="Interlinking Integration"
            defaultValue={props.value[key]}
            value={props.value[key]}
            onChange={(e) => {
              setValue(key, e.target.value);
            }}
          >
            {integrationState.integrations &&
              integrationState.integrations.map((t: any) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
          </TextField>
        );
      case "textarea":
        return (
          <TextField
            fullWidth
            multiline
            minRows={3}
            value={props.value[key]}
            maxRows={3}
            label={setting.name}
            helperText={setting.desc}
            onChange={(e) => {
              setValue(key, e.target.value);
            }}
            key={key}
          ></TextField>
        );
    }
    return <></>;
  };

  if (!settingsData) {
    return <></>;
  }

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return <>{Object.keys(settingsData).map((s) => getSetting(s, settingsData[s]))}</>;
};
