import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../common/components/loader";
import DashboardLayout from "../../../common/layouts/dashboard";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { homeSlice } from "../../home";
import { NichePigCreateDialog } from "./niche-pig-create-dialog";
import NichePigDesc from "./niche-pig-desc";
import NichePigHeader from "./niche-pig-header";
import NichePigLoading from "./niche-pig-loading";
import NichePigLocked from "./niche-pig-locked";
import NichePigNavHeader from "./niche-pig-nav-bar";
import toolsSlice from "./niche-pig.slice";
import NichePigResults from "./results/results";

const NichePig = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const toolsState = useSelector((state: RootState) => state.nichePig);
  const navigate = useNavigate();
  const hasAccess = auth.tools?.nicheTool;

  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("niche-tool"));
    dispatch(toolsSlice.actions.setType("NICHE"));
    if (hasAccess) {
      dispatch(toolsSlice.actions.setStatus("initialized"));
    } else {
      navigate("/tools");
    }
  }, [homeSlice]);
  const [loading, setLoading] = useState(true);

  const NichePigLoader = () => {
    return <Loader />;
  };

  const handleOpen = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(true));
  };

  const handleClose = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(false));
  };

  return (
    <DashboardLayout active={"niche-pig"} name="Niche Pig">
      <Box>
        {toolsState.tool.status === "loading" && <NichePigLoader />}
        {toolsState.tool.status === "initialized" && (
          <>
            <NichePigHeader />
            <NichePigNavHeader />
            <NichePigDesc />
            <NichePigCreateDialog
              onClose={handleClose}
              open={toolsState.tool.createDialogOpened}
            />
          </>
        )}
        {toolsState.tool.status === "unauthorized" && <NichePigLocked />}
        {/* Show when query is in progress */}
        {toolsState.tool.status === "creating" && (
          <>
            <NichePigHeader />
            <NichePigNavHeader />
            <NichePigLoading />
          </>
        )}
        {toolsState.tool.status === "created" && (
          <>
            <NichePigHeader />
            <NichePigNavHeader />
            <NichePigResults />
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default NichePig;
