import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import HeadlinePigLogo from "./headline-pig-logo.png";
import toolsSlice from "./headline-pig.slice";

const HeadlinePigDesc = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const handleOpen = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(true));
  };
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          my: 10,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
            my: 3,
          }}
        >
          {" "}
          <img src={HeadlinePigLogo} alt="Headline Pig Pro" width={"250px"} />
        </Box>

        <Typography align="center" variant="h4" sx={{ my: 2 }}>
          Find Your Next Headline With Headline Pig Pro
        </Typography>
        <Typography align="center" color="text.primary" variant="body1">
          Headline Pig Pro Allows You To Quickly Find Buyer-Intent Headlines And
          Export Them To Create a Full Content Posting Plan
        </Typography>
        <Stack sx={{ my: 1 }}>
          <Button onClick={handleOpen} variant="contained" sx={{ mt: 2 }}>
            New Search
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default HeadlinePigDesc;
