import { Container, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
const SIDE_NAV_WIDTH = 270;
const PANEL_WIDTH = 300;

export const EditorLayout = (props: any) => {
  const { children, active } = props;

  const LayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: SIDE_NAV_WIDTH,
      paddingRight: PANEL_WIDTH,
    },
  }));

  const LayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
  });

  const ChildWrapper = styled("div")({
    minHeight: "90vh",
    marginRight: "20px",
    marginTop: "20px",
  });

  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <>
      <LayoutRoot sx={{}}>
        <LayoutContainer>
          <ChildWrapper>
            <Container>{children}</Container>
          </ChildWrapper>
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default EditorLayout;
