import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import ampifire from "./ampifire.png";
import paykickstart from "./paykickstart.png";

type Props = {};

const Subscriptions = (props: Props) => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const sortedSubs = authState.subscriptions?.subscriptions
    ? [...authState.subscriptions?.subscriptions].sort(
      (a, b) =>
        (new Date(b.startDate).getTime() || 0) -
        (new Date(a.startDate).getTime() || 0)
    )
    : [];
  const getProcessorLogo = (processor: string) => {
    switch (processor) {
      case "paykickstart":
        return <img src={paykickstart} width="150px" />;
      case "recurly":
        return <img src={ampifire} width="150px" />;
      case "clickbank":
        return <img src={ampifire} width="150px" />;
    }
  };

  if (!authState.subscriptions?.subscriptions) {
    return <></>;
  }
  return (
    <Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="subscription table">
          <TableHead>
            <TableRow>
              <TableCell>Subscriptions</TableCell>{" "}
              {/* Assuming planId represents the name */}
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Rebill Date</TableCell>
              <TableCell>Status</TableCell> {/* Left-align for text data */}
              <TableCell>Payment Processor</TableCell>{" "}
              {/* Left-align for text data */}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedSubs &&
              sortedSubs.map((sub) => (
                <TableRow
                  key={sub.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {
                      authState.plan?.plans.filter(
                        (p) => p._id === sub.planId
                      )[0].name
                    }
                  </TableCell>
                  <TableCell align="right">
                    {new Date(sub.startDate).toLocaleDateString()}{" "}
                    {/* Improved date format */}
                  </TableCell>
                  <TableCell align="right">
                    {sub.endDate
                      ? new Date(sub.endDate).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {sub.rebillDate
                      ? new Date(sub.rebillDate).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: sub.status === "active" ? "green" : "red",
                      }}
                    >
                      {sub.status}
                    </span>
                  </TableCell>
                  <TableCell>{getProcessorLogo(sub.processor)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter sx={{ my: 4 }}>
        {(!sortedSubs || sortedSubs.length === 0) && (
          <>
            <Stack sx={{ textAlign: "center" }}>No Subscriptions Found</Stack>
          </>
        )}
      </TableFooter>
    </Stack>
  );
};

export default Subscriptions;
