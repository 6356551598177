import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/useStore";
import { useUserData } from "../hooks/useUserData";
import { RootState } from "../store/store";

function RequireAuth() {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();
  const userData = useUserData();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      await userData.getUser();
      setLoading(false);
    };
    getUser();
  }, []);

  if (loading) {
    return <></>;
  }

  return <Outlet />;
}

export default RequireAuth;
