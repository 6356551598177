import SwapHorizOutlined from "@mui/icons-material/SwapHorizOutlined";
import { Avatar, Box, Button, Stack, Typography, alpha } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { AddImageDialog } from "../dialog/add-image-dialog";
import editorSlice from "./editor.slice";
import EmptyFeaturedImage from "./empty-featured-image.png";

type Props = {
  featuredImage?: string | null;
  disabled?: boolean;
};

export default function FeaturedImage(props: Props) {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const editorState = useSelector((state: RootState) => state.editor);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);

  const handleOpenImagePopup = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImagePopup = () => {
    setOpenImageDialog(false);
  };

  const handleInsertImage = async (
    selected: {
      url: string;
      attribution?: string;
    } | null,
  ) => {
    //Set featured image
    dispatch(editorSlice.actions.setTransitionLoading("Updating featured image..."));
    try {
      const response = await axios.post(`${apiUrl}/article/update`, {
        id: editorState.article?.id,
        featuredImage: selected ? selected.url : null,
      });
      const article = response.data.result;
      toast.success("Successfully updated featured image!");

      dispatch(editorSlice.actions.setArticle(article));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
    }
    dispatch(editorSlice.actions.setTransitionLoading(null));
  };

  return (
    <Box>
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={(theme) => {
          return {
            background: "#ffffff",
          };
        }}
        disabled={props.disabled}
        onClick={handleOpenImagePopup}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ my: "10px" }}
          // onClick={openIntegrationPopup}
        >
          <Avatar
            src={props.featuredImage || EmptyFeaturedImage}
            alt="Featured Image"
            variant="square"
            sx={{
              width: "40px",
              height: "40px",
              opacity: props.disabled ? 0.5 : 1,
              bgcolor: "gray",
              borderRadius: "5px",
            }}
          />
          <Stack
            direction="column"
            alignItems="left"
            justifyContent="center"
            sx={{ height: "100%", textAlign: "left" }}
          >
            <Typography
              variant="body2"
              sx={{
                color: (theme) => (props.disabled ? theme.palette.grey[300] : theme.palette.grey[500]),
                lineHeight: 1.2,
                fontWeight: 500,
              }}
            >
              Featured Image
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) =>
                  props.disabled ? alpha(theme.palette.secondary.main, 0.5) : theme.palette.secondary.main,
                lineHeight: 1.2,
              }}
            >
              {!props.featuredImage ? "No Image Added" : "Image Added"}
            </Typography>
          </Stack>
          <SwapHorizOutlined
            sx={{
              color: (theme) => (props.disabled ? theme.palette.grey[300] : theme.palette.grey[500]),
            }}
          />
        </Stack>
      </Button>
      <AddImageDialog
        open={openImageDialog}
        onClose={handleCloseImagePopup}
        onInsertImage={handleInsertImage}
        initialImage={props.featuredImage ? { img: props.featuredImage, title: "", author: "" } : undefined}
        disableImageProxy
      />
    </Box>
  );
}
