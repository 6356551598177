import { CircularProgress, Stack } from "@mui/material";

export const Loader = () => {
  return (
    <>
      <Stack alignItems="center" sx={{ marginTop: "40px" }}>
        <CircularProgress />
      </Stack>
    </>
  );
};
