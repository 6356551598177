import { CircularProgress, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";

const PaykickstartEmbed = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [loading, setLoading] = useState<boolean>(false);
  const [auth, setAuth] = useState<string | null>(null);

  const getAuth = async () => {
    try {
      setLoading(true);
      const response: any = await axios.post(`${apiUrl}/user/get-pks-auth`, {});
      const result = response.data.result;
      const auth = result.auth;
      setAuth(auth);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAuth();
  }, [authState.user?.email]);

  return (
    <Stack>
      {loading && <CircularProgress size={15} sx={{ mr: "10px" }} color="inherit" />}

      <>
        <iframe
          width="100%"
          frameBorder="0"
          style={{ height: "100vh" }}
          src={
            "https://app.paykickstart.com/billing?portal=xBMuWOpv9bQaw8s82izoaJJQlVRYPtKsFAEKU1m4k0XpFpkdil" +
            (auth ? "&secret=" + auth : "")
          }
        ></iframe>
        <script src="https://app.paykickstart.com/billing-portal/js/xBMuWOpv9bQaw8s82izoaJJQlVRYPtKsFAEKU1m4k0XpFpkdil"></script>
      </>
    </Stack>
  );
};

export default PaykickstartEmbed;
