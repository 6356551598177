import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { ArticleDataSource } from "../../../interfaces/article.interface";

export const SourceCard = (props: {
  data: ArticleDataSource;
  onRemove?: (id: string) => void;
  disabled?: boolean;
}) => {
  const source = props.data;
  const domain = source.data.domain;
  let desc = source.data.desc as string;
  let title = source.title;

  if (desc.length > 80) desc = desc.substring(0, 80) + "...";
  if (title.length > 80) title = title.substring(0, 80) + "...";

  return (
    <Card sx={{ mt: "15px" }}>
      <>
        <CardContent>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {domain}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: 15 }}>
            {title}
          </Typography>
          <Typography variant="body2">{desc}</Typography>
        </CardContent>

        <CardActions sx={{ paddingTop: "0px" }}>
          <Button
            size="small"
            disabled={props.disabled}
            color="secondary"
            variant="text"
            onClick={() => {
              if (props.onRemove) props.onRemove(props.data._id as string);
            }}
          >
            Remove Source
          </Button>
        </CardActions>
      </>
    </Card>
  );
};
