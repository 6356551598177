// import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useAppDispatch } from "@wordform/core/hooks/useStore";
import { RootState } from "@wordform/core/store/store";
import { PaddleAPIResponse } from "./paddle.slice";
import paddleSlice from "./paddle.slice";

const usePaddlePlans = () => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();

  const getPlans = async () => {
    try {
      const response = await axios.get(`${apiUrl}/paddle/paddle`);
      const result: PaddleAPIResponse = response.data.result;
      dispatch(paddleSlice.actions.setPaddlePlans(result));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting plans...");
      }
    }
  };

  const refreshPlans = async () => {
    dispatch(paddleSlice.actions.reset());
    await getPlans();
  };

  return { getPlans, refreshPlans };
};

export default usePaddlePlans;
