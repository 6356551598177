import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import { Box, Container, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import Logo from "../../logo/logo";
import AccountPopover from "./account-popover";

const TOP_NAV_HEIGHT = 64;
const organizations = [
  {
    id: "60cffc5da77cd8d2b12246e9",
    name: "Devias IO",
  },
  {
    id: "22ac644657adeaa97c33562b",
    name: "Acme",
  },
];
export const TopNav = (props: any) => {
  const { onNavOpen } = props;
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const authState = useSelector((state: RootState) => state.auth);

  return (
    <Box
      component="header"
      sx={{
        backgroundColor: "neutral.900",
        color: "common.white",
        position: "fixed",
        width: "100%",
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container maxWidth={"xl"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 3,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={3}>
            <Box
              sx={{
                display: "inline-flex",
                height: 28,
                width: 28,
              }}
            >
              <Logo light />
            </Box>
            {!mdDown && <></>}
            {mdDown && (
              <IconButton color="inherit" onClick={onNavOpen}>
                <SvgIcon color="action" fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <AccountPopover
              name={authState.user?.firstName + " " + authState.user?.lastName}
              email={authState.user?.email}
              plan={"Plan: " + (authState.plan?.selected?.name || "No Subscription")}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default TopNav;
