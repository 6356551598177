import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import DashboardLayout from "../../common/layouts/dashboard";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { homeSlice } from "../home";

const Training = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("training"));
    getTraining();
  }, [dispatch]);

  const [loading, setLoading] = useState(true);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [training, setTraining] = useState(
    [] as [{ name: string; html: string }] | any
  );
  const [tab, setTab] = useState<string>("0");

  useEffect(() => {
    document.title = "Wordfrom AI - Training";
  }, []);

  const getTraining = async () => {
    try {
      setLoading(true);
      const data = {};
      const response: any = await axios.get(`${apiUrl}/training/get`, data);
      const result = response.data.result;
      setTraining(result);
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting training...");
      }
    }
    setLoading(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <DashboardLayout active={"training"} name="Training">
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {training.map((t: { name: string; html: string }, k: number) => {
              return <Tab label={t.name} value={k.toString()} />;
            })}
          </TabList>
        </Box>
        {training.map((t: { name: string; html: string }, k: number) => {
          return (
            <TabPanel value={k.toString()}>
              <div dangerouslySetInnerHTML={{ __html: t.html }}></div>
            </TabPanel>
          );
        })}
      </TabContext>
    </DashboardLayout>
  );
};

export default Training;
