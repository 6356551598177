import PlusIcon from "@mui/icons-material/Add";
import {
  Button,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import NichePigHeaderCredits from "./niche-pig-header-credits";
import toolsSlice from "./niche-pig.slice";

const NichePigHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const toolsState = useSelector((state: RootState) => state.nichePig);
  const handleOpen = () => {
    if (toolsState.tool.status === "initialized") {
      dispatch(toolsSlice.actions.setCreateDialogOpened(true));
    } else {
      dispatch(toolsSlice.actions.setStatus("initialized"));
      dispatch(toolsSlice.actions.setCreateDialogOpened(true));
    }
  };

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="baseline">
          <Typography variant="h4">Niche Pig Pro</Typography>
          <NichePigHeaderCredits />
        </Stack>
        {["initialized", "created"].includes(toolsState.tool.status) && (
          <Button
            onClick={handleOpen}
            size="large"
            startIcon={
              <SvgIcon fontSize="small">
                <PlusIcon />
              </SvgIcon>
            }
            variant="contained"
          >
            New Search
          </Button>
        )}
      </Stack>
    </>
  );
};

export default NichePigHeader;
