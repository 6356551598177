import { useSelector } from "react-redux";
import DashboardLayout from "../../common/layouts/dashboard";
import { RootState } from "../../core/store/store";
import { BillingInactiveDialog } from "../billing/billing-inactive-dialog";
import ProjectsFilter from "./projects-filter";
import ProjectsHeader from "./projects-header";
import ProjectsList from "./projects-list";
import { useEffect } from "react";

const Projects = () => {
  //Show projects if they have a Wordform plan
  const authState = useSelector((state: RootState) => state.auth);
  const noWordformPlan = authState.plan?.selected == null;

  useEffect(() => {
    document.title = "Wordfrom AI - Projects";
  }, []);
  
  return (
    <DashboardLayout active={"projects"} name="Projects">
      <BillingInactiveDialog open={noWordformPlan} />
      <ProjectsHeader />
      <ProjectsFilter />
      <ProjectsList />
    </DashboardLayout>
  );
};

export default Projects;
