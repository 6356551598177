import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

export type CreateCustomSourceData = {
  type: string;
  title: string;
  text?: string;
  url?: string;
};

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: (data: CreateCustomSourceData) => void;
};

export const CreateCustomSourceDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    formState,
  } = useForm<CreateCustomSourceData>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CreateCustomSourceData> = async (data) => {
    props.onSubmit(data);
    props.onClose();
  };

  const type = watch("type");

  return (
    <Dialog maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Create a Custom Source
          <DialogContentText>
            Custom sources can be created by providing a URL or custom text.
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              select
              label="Source Type"
              defaultValue="text"
              {...register("type", { required: true, minLength: 2 })}
            >
              <MenuItem value={"text"}>Raw Text</MenuItem>
              <MenuItem value={"url"}>URL</MenuItem>
            </TextField>
            {formState.errors.type && (
              <FormHelperText error={true}>
                Please select a source type!
              </FormHelperText>
            )}
            <TextField
              fullWidth
              label="Source Title"
              {...register("title", { required: true, minLength: 1 })}
            />
            {formState.errors.title && (
              <FormHelperText error={true}>
                Please enter a title!
              </FormHelperText>
            )}
            {type === "text" && (
              <>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Source Text"
                  {...register("text", {
                    validate: {
                      required: (value: any) => {
                        if (
                          getValues("type") === "text" &&
                          (value.length < 10 || value.length > 30000)
                        )
                          return "Please enter your source text!";
                      },
                    },
                  })}
                />
                {formState.errors.text && (
                  <FormHelperText error={true}>
                    Please enter your source text!
                  </FormHelperText>
                )}
              </>
            )}
            {type === "url" && (
              <>
                <TextField
                  fullWidth
                  label="Source URL"
                  {...register("url", {
                    validate: {
                      validateDomain: (s: any) => {
                        if (getValues("type") !== "url") return true;
                        if (!s) return false;
                        let url;
                        try {
                          url = new URL(s);
                        } catch (e) {
                          return "Please enter a valid URL!";
                        }
                        return /https?/.test(url.protocol)
                          ? true
                          : "Please enter a valid URL!";
                      },
                      required: (value: any) => {
                        if (
                          getValues("url") === "text" &&
                          (value.length < 10 || value.length > 30000)
                        )
                          return "Please enter your source url!";
                      },
                    },
                  })}
                />
                {formState.errors.text && (
                  <FormHelperText error={true}>
                    Please enter your source URL!
                  </FormHelperText>
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            disabled={!formState.isValid || loading}
          >
            Create Custom Source
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
