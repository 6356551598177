import PaymentsIcon from "@mui/icons-material/Payments";
import {
  Button
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { NichePigUnlimitedDialog } from "./niche-pig-unlimited-dialog";
import toolsSlice from "./niche-pig.slice";

const NichePigHeaderCredits = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const authState = useSelector((state: RootState) => state.auth);
  const toolsState = useSelector((state: RootState) => state.nichePig);
  const unlimited = authState.tools?.nicheTool === "unlimited";
  const creditsAvail = !unlimited ? authState.tools?.nicheTool : 0;

  const handleOpen = () => {
    dispatch(toolsSlice.actions.setUnlimitedDialogOpened(true));
  };

  const handleClose = () => {
    dispatch(toolsSlice.actions.setUnlimitedDialogOpened(false));
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<PaymentsIcon />}
        onClick={!unlimited ? handleOpen : () => { }}
        sx={{
          borderColor: "#D3D3D3", // light gray border
          color: "black", // text color
          backgroundColor: "white", // background color
          ":hover": {
            backgroundColor: "#f5f5f5", // slightly darker gray on hover
            borderColor: "#C0C0C0", // darker border on hover
          },
          padding: "6px 12px", // padding inside the button
          mx: 3,
        }}
      >
        {unlimited ? "Unlimited" : creditsAvail} Credits
      </Button>
      <NichePigUnlimitedDialog
        open={toolsState.tool.unlimitedDialogOpened}
        onClose={handleClose}
      />
    </>
  );
};

export default NichePigHeaderCredits;
