import { useNavigate } from "react-router-dom";
import logoLight from "./wordformai-light.svg";
import logoDark from "./wordformai-dark.svg";

type Props = {
  light?: boolean;
  dark?: boolean;
  width?: string;
};
const Logo = (props: Props) => {
  const navigate = useNavigate();

  return (
    <a
      href="#"
      onClick={() => {
        navigate("/");
      }}
    >
      {props.dark || !props.light ? (
        <img
          src={logoDark}
          style={{ width: props.width ? props.width : "150px" }}
          alt="Wordform AI"
        />
      ) : (
        <img
          src={logoLight}
          style={{ width: props.width ? props.width : "150px" }}
          alt="Wordform AI"
        />
      )}
    </a>
  );
};

export default Logo;
