import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../common/components/loader";
import DashboardLayout from "../../../common/layouts/dashboard";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { homeSlice } from "../../home";
import { HeadlinePigCreateDialog } from "./headline-pig-create-dialog";
import HeadlinePigDesc from "./headline-pig-desc";
import HeadlinePigHeader from "./headline-pig-header";
import HeadlinePigLoading from "./headline-pig-loading";
import HeadlinePigLocked from "./headline-pig-locked";
import HeadlinePigNavHeader from "./headline-pig-nav-bar";
import toolsSlice from "./headline-pig.slice";
import HeadlinePigResults from "./results/results";

const HeadlinePig = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const toolsState = useSelector((state: RootState) => state.headlinePig);
  const navigate = useNavigate();
  const hasAccess = auth.tools?.headlineTool;

  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("headline-tool"));
    dispatch(toolsSlice.actions.setType("HEADLINE"));
    if (hasAccess) {
      dispatch(toolsSlice.actions.setStatus("initialized"));
    } else {
      navigate("/tools");
    }
  }, [homeSlice]);
  const [loading, setLoading] = useState(true);

  const HeadlinePigLoader = () => {
    return <Loader />;
  };

  const handleOpen = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(true));
  };

  const handleClose = () => {
    dispatch(toolsSlice.actions.setCreateDialogOpened(false));
  };

  return (
    <DashboardLayout active={"headline-pig"} name="Headline Pig">
      <Box>
        {toolsState.tool.status === "loading" && <HeadlinePigLoader />}
        {toolsState.tool.status === "initialized" && (
          <>
            <HeadlinePigHeader />
            <HeadlinePigNavHeader />
            <HeadlinePigDesc />
            <HeadlinePigCreateDialog
              onClose={handleClose}
              open={toolsState.tool.createDialogOpened}
              defaultNiche={toolsState.tool.defaultSearch}
            />
          </>
        )}
        {toolsState.tool.status === "unauthorized" && <HeadlinePigLocked />}
        {/* Show when query is in progress */}
        {toolsState.tool.status === "creating" && (
          <>
            <HeadlinePigHeader />
            <HeadlinePigNavHeader />
            <HeadlinePigLoading />
          </>
        )}
        {toolsState.tool.status === "created" && (
          <>
            <HeadlinePigHeader />
            <HeadlinePigNavHeader />
            <HeadlinePigResults />
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default HeadlinePig;
