import { Box } from "@mui/material";
import DashboardLayout from "../../common/layouts/dashboard";
import AccountPassword from "./account-password";
import AccountProfile from "./account-profile";
import { useEffect } from "react";

type ProfileForm = { firstName: string; lastName: string };

const Account = () => {
  useEffect(() => {
    document.title = "Wordfrom AI - Account";
  }, []);

  return (
    <DashboardLayout active={"account"} name="Account Settings">
      <Box>
        <AccountPassword />
      </Box>
    </DashboardLayout>
  );
};

export default Account;
