import axios from "axios";
import _ from "lodash";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { useUserData } from "../../../core/hooks/useUserData";
import { RootState } from "../../../core/store/store";
import { ArticleResponse } from "../../../interfaces/article.interface";
import editorSlice from "./editor.slice";

const useGenerateArticle = <T>(editorRef: RefObject<RichTextEditorRef>) => {
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const userData = useUserData();

  const getArticle = async (articleId: string) => {
    try {
      const response: any = await axios.post(`${apiUrl}/article/get`, {
        id: articleId,
      });
      const result = response.data.result as ArticleResponse;
      dispatch(editorSlice.actions.setArticle(result));

      return result.generating;
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting article...");
      }
    }
  };

  const getArticleContentGenerated = async (
    articleId: string,
    openedEditor: "outline" | "article"
  ): Promise<{ generating: boolean; content: string | null }> => {
    try {
      const response: any = await axios.post(`${apiUrl}/article/get`, {
        id: articleId,
      });
      const result = response.data.result as ArticleResponse;
      const content =
        openedEditor === "outline"
          ? (result.outline as string)
          : (result.content as string);

      const formattedContent = (content || "")
        .split("\n")
        .join("")
        .split("<p></p>")
        .join("");

      return {
        generating: result.generating,
        content: formattedContent,
      };
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error getting article...");
      }

      return { generating: false, content: null };
    }
  };

  const setEditorContent = (content: string) => {
    const blinkingCursor = `<span className="blink" style="background: #0d6efd; opacity: 0.8; padding-left: 3px;">&nbsp;</span>`;
    if (content)
      editorRef.current?.editor
        ?.chain()
        .setContent(content + blinkingCursor)
        .run();
  };

  const runFetchGenerationLoop = async (
    articleId: string,
    openedEditor: "outline" | "article"
  ) => {
    //Fetch initial article
    await getArticle(articleId as string);

    //Refresh user state
    userData.getUser();

    const currentUrl = window.location.href;

    setEditorContent("Loading ...");
    let continueRun = true;
    let currIndex = 0;
    while (continueRun) {
      await new Promise((resolve) => setInterval(resolve, 500));
      const { generating, content } = await getArticleContentGenerated(
        articleId as string,
        openedEditor
      );

      if (!generating) {
        await getArticle(articleId as string);
        continueRun = false;
        return;
      }

      if (!content || content?.length === 0) {
        setEditorContent("Generating content...");
        continue;
      };

      if (currIndex === 0 && content.length > 500)
        currIndex = content.length - 50;
      else if (content.length - currIndex > 300) {
        currIndex = content.length - 300;
      }

      for (currIndex; currIndex < content.length; currIndex++) {
        if (!generating) continue;
        if (currentUrl !== window.location.href) return;
        setEditorContent(content.substring(0, currIndex));
        await new Promise((resolve) => setInterval(resolve, 20));
      }
    }
  };

  const runFetchGenerationLoopDebounced = _.debounce(
    runFetchGenerationLoop,
    3000
  );

  return { runFetchGenerationLoopDebounced };
};

export default useGenerateArticle;
