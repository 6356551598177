import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Background from "./common/background/background";
import { useAppDispatch } from "./core/hooks/useStore";
import Routing from "./core/routing/routing";
import { RootState } from "./core/store/store";
import { homeSlice } from "./features/home";
import { createTheme } from "./theme";

function App() {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();

  // Send cookies with every request
  axios.defaults.withCredentials = true;
  // Set default request timeout to 10 mins
  axios.defaults.timeout = 1000 * 60 * 10;

  // Request error handling middleware for 500 status code
  axios.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 500) {
      toast.error("Something went wrong, please try again later!");
    }
    return Promise.reject(error);
  });

  const theme = createTheme({
    colorPreset: "purple",
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app-container">
        <div className="app-content">
          <Background />
          <Routing />
        </div>
      </div>
      <Toaster position="top-center" />
    </ThemeProvider>
  );
}

export default App;
