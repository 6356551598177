import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../common/components/loader";
import { RootState } from "../../core/store/store";
import { ArticleSharedResponse } from "../../interfaces/article.interface";
import sanitize from "sanitize-html";
import { func } from "prop-types";

function spacingStyle(value: number) {
  return `${value}rem`;
}

const useStyles = makeStyles((theme: any) => ({
  content: {
    "& h1": {
      fontSize: "2.5rem",
      lineHeight: 1.2,
      marginY: spacingStyle(2),
      paddingBottom: "20px",
      fontWeight: 500,
    },
    "& h2": {
      fontSize: "2rem",
      lineHeight: 1.2,
      marginY: spacingStyle(2),
      paddingBottom: "20px",
      fontWeight: 500,
    },
    "& h3": {
      fontSize: "1.5rem",
      lineHeight: 1.2,
      marginY: spacingStyle(1.5),
      paddingBottom: "20px",
      fontWeight: 500,
    },
    "& p": {
      fontSize: "1.2rem",
      lineHeight: 1.8,
      marginY: spacingStyle(1),
      paddingBottom: "10px",
    },
    "& li": {
      fontSize: "1.1rem",
      paddingBottom: "8px",
    },

    "& ul, & ol": {
      paddingLeft: spacingStyle(4),
      fontSize: "1rem",
      lineHeight: 1.5,
      marginY: spacingStyle(1),
      paddingBottom: "10px",
    },
    "& a": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& img": {
      maxWidth: "100%",
      paddingBottom: "10px",
    },
    "& blockquote": {
      marginY: spacingStyle(2),
      padding: spacingStyle(2),
      fontStyle: "italic",
      fontSize: "1.2em",
      lineHeight: 1.6,
    },
  },
}));

const Share = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [sharedArticle, setSharedArticle] = useState<ArticleSharedResponse | undefined>();
  const navigate = useNavigate();
  const classes = useStyles();

  const getSharedArticle = async (id: string) => {
    try {
      setLoading(true);
      const response: any = await axios.post(`${apiUrl}/article/get-shared-article`, { id });
      const result: ArticleSharedResponse = response.data.result;
      setSharedArticle(result);
    } catch (e: any) {
      toast.error("Article not found!");
      navigate("/error/notfound");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) getSharedArticle(id);
  }, [id]);

  if (loading || !sharedArticle) {
    return (
      <>
        <Loader />
      </>
    );
  }

  //Safe elements
  const clean = sanitize(sharedArticle.content, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "a", // Basic formatting
      "p",
      "ul",
      "ol",
      "li", // List elements
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6", // Headers
      "div",
      "span", // Containers
      "img", // Images
      "blockquote", // Blockquotes
      "code",
      "pre", // Code formatting
      "br",
      "hr", // Breaks and horizontal rules
      "table",
      "thead",
      "tbody",
      "tfoot",
      "tr",
      "td",
      "th", // Table elements
    ],
    allowedAttributes: {
      a: ["href", "target", "rel"], // Attributes for anchor tags
      img: ["src", "alt", "title", "width", "height"], // Attributes for images
      // You can add more attributes for other tags as needed
    },
    // Additional options can be added as needed, such as allowed CSS classes, styles, etc.
  });

  return (
    <Container maxWidth="md">
      <Box py={2}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="div" color="primary">
              Yes, This Article Was Written By AI!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Wordform AI is a new AI-powered writing tool that writes entire articles in minutes.
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="inherit" size="small" href="https://wordform.ai" target="_blank">
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Box py={6}>
        <Box textAlign="center" mb={3}>
          <Container maxWidth="sm">
            <Chip
              label={new Date(sharedArticle.updatedAt).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              color="primary"
            />

            <Box my={4}>
              <Typography sx={{ fontSize: "2.4rem", fontWeight: 500 }}>{sharedArticle.keyword}</Typography>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Avatar alt="" src={"https://gravatar.com/avatar/" + sharedArticle.userAvatar} />
              <Box ml={2} textAlign="left">
                <Typography variant="subtitle1">{sharedArticle.userName}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Wordform AI User
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box pl={{ md: 8 }} pr={{ md: 8 }} className={classes.content}>
          {sharedArticle.featuredImage && (
            <img src={sharedArticle.featuredImage || ""} alt="Featured cover of article" />
          )}
          <div dangerouslySetInnerHTML={{ __html: clean }} />
        </Box>
      </Box>
    </Container>
  );
};

export default Share;
