import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";

type Props = {
  available: number;
  used: number;
  label: string;
};

export const UsageBar = (props: Props) => {
  if (props.available === 0) {
    return null;
  }
  return (
    <Box sx={{ display: "flex", alignItems: "left", flexDirection: "column", pt: 0.5, pb: 0.5 }}>
      <Box sx={{ display: "flex", alignItems: "left" }}>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant="body2" color="text.primary" fontWeight="bold">
            {props.label}
          </Typography>
        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        value={Math.round((props.used / props.available) * 100)}
        sx={{ height: 4, width: "100%", mt: 0.5, mb: 0.5 }}
      />
      <Box sx={{ display: "flex", flexDirection: "row", mt: -0.4 }}>
        <Typography variant="caption" color="text.primary" fontWeight="bold" sx={{ display: "flex", flexGrow: "1" }}>
          {props.used}
        </Typography>
        <Typography variant="caption" color="text.primary" sx={{ display: "flex", alignItems: "right", mt: 0 }}>
          out of {props.available > 1000000 ? "♾️" : props.available}
        </Typography>
      </Box>
    </Box>
  );
};

export default UsageBar;
