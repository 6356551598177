import { Box, Button, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Scrollbar } from "../../../common/components/scrollbar";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import editorSlice from "../editor/editor.slice";
import { SourceCard } from "./source-card";

export const Sources = (props: any) => {
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const onRemoveSource = async (id: string) => {
    try {
      dispatch(editorSlice.actions.setTransitionLoading("Removing source..."));
      const sources = editorState.article?.sources?.filter((s) => s._id !== id);
      const response = await axios.post(`${apiUrl}/article/update-sources`, {
        id: editorState.article?.id,
        sources,
      });
      const article = response.data.result;
      toast.success("Successfully removed source from article.");
      dispatch(editorSlice.actions.setArticle(article));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
    }
    dispatch(editorSlice.actions.setTransitionLoading(null));
  };

  const handleAddNewSource = () => {
    dispatch(
      editorSlice.actions.setAddSourcesDialogOpened({
        opened: true,
        update: true,
      }),
    );
  };

  return (
    <Scrollbar
      forceVisible={true}
      sx={{
        height: "100%",
        overflowX: "hidden",
        "& .simplebar-content": {
          height: "100%",
        },
      }}
    >
      <Box sx={{ py: "10px" }}>
        {editorState.article?.sources?.map((s, k) => {
          return (
            <div key={k}>
              <SourceCard data={s} onRemove={onRemoveSource} disabled={editorState.article?.generating} />
            </div>
          );
        })}
        <Container sx={{ my: "15px" }}>
          {editorState.article?.sources?.length === 0 && (
            <Stack direction="column" alignItems="center" sx={{ my: "15px", px: "15px" }}>
              <Typography variant="h6">No Sources Added</Typography>
              <Typography variant="body2">There are no sources added yet to your article.</Typography>
            </Stack>
          )}

          <Stack>
            <Button onClick={handleAddNewSource} disabled={editorState.article?.generating} variant="outlined">
              Update Sources
            </Button>
          </Stack>
        </Container>
      </Box>
    </Scrollbar>
  );
};
