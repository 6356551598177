import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { authSlice } from "../../../features/auth/auth";
import { usePopover } from "../../../hooks/use-popover";
import { Link } from "react-router-dom";
import md5 from "md5";

const getGravatarUrl = (email: string) => {
  if (!email) return `https://www.gravatar.com/avatar/404?d=404`;
  const hash = md5(email.trim().toLowerCase());
  // Return a 404 image if the email doesn't have a gravatar
  return `https://www.gravatar.com/avatar/${hash}?d=404`;
};

const UserAvatar = ({ email }) => {
  const gravatarUrl = getGravatarUrl(email);

  return (
    <Avatar variant="rounded">
      <object type="image/png" data={gravatarUrl} style={{ width: "100%", height: "100%" }}>
        {/* Fallback to default icon */}
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <PersonIcon />
        </Box>
      </object>
    </Avatar>
  );
};

export const AccountPopover = (props: any) => {
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const popover = usePopover();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user/logout`);
      if (response.data?.success) {
        toast.success("Logged out successfully!");
      } else {
        toast.error("Logout failed: " + response.data.error.message);
      }
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error logging out...");
      }
    }
    dispatch(authSlice.actions.setUser({}));
    navigate("/");
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        spacing={2}
        sx={{ cursor: "pointer" }}
      >
        <UserAvatar email={props.email} />
        {!mdDown && (
          <>
            <Box sx={{ minWidth: 100 }}>
              <Typography color="neutral.400" variant="caption">
                {props.plan}
              </Typography>
              <Typography color="inherit" variant="subtitle2">
                {props.email}
              </Typography>
            </Box>
            <SvgIcon color="action" fontSize="small">
              <ChevronDownIcon />
            </SvgIcon>
          </>
        )}
      </Stack>
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ sx: { width: 260 } }}
      >
        <List>
          {mdDown && (
            <ListItemButton component={Link} to={"/"} divider onClick={popover.handleClose}>
              <ListItemIcon>
                <SvgIcon fontSize="small"></SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItemButton>
          )}
          {mdDown && (
            <ListItemButton component={Link} to={"/integrations"} divider onClick={popover.handleClose}>
              <ListItemIcon>
                <SvgIcon fontSize="small"></SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Integrations" />
            </ListItemButton>
          )}
          <ListItemButton component={Link} to={"/account/update"} divider onClick={popover.handleClose}>
            <ListItemIcon>
              <SvgIcon fontSize="small"></SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/billing"} divider onClick={popover.handleClose}>
            <ListItemIcon>
              <SvgIcon fontSize="small"></SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItemButton>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <SvgIcon fontSize="small"></SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

export default AccountPopover;
