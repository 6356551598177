import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../common/layouts/dashboard";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { homeSlice } from "../home";
import ToolsHeader from "./tools-header";
import ToolsList from "./tools-list";

const Tools = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("tools"));
  }, [dispatch]);
  const [loading, setLoading] = useState(true);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [tab, setTab] = useState<string>("0");

  useEffect(() => {
    document.title = "Wordfrom AI - Tools";
  }, []);

  return (
    <DashboardLayout active={"tools"} name="Tools">
      <ToolsHeader />
      <ToolsList />
    </DashboardLayout>
  );
};

export default Tools;
