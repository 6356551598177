import {
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { default as ReactMarkdown } from "react-markdown";
import remarkGfm from "remark-gfm";

export default function Markdown({ markdown }: { markdown: string }) {
  const theme = useTheme();

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        // *********
        // * Links *
        // *********
        a: ({ href, title, children }) => (
          <Link href={href} underline={"always"}>
            {children}
          </Link>
        ),

        // ********
        // * Text *
        // ********
        p: ({ children }) => <Typography sx={{ mt: 1 }}>{children}</Typography>,
        del: ({ children }) => (
          <Typography sx={{ mt: 1, textDecoration: "line-through" }}>
            {children}
          </Typography>
        ),
        em: ({ children }) => (
          <Typography sx={{ mt: 1, fontStyle: "italic" }}>
            {children}
          </Typography>
        ),
        strong: ({ children }) => (
          <Typography sx={{ mt: 1, fontWeight: "bold" }}>{children}</Typography>
        ),
        b: ({ children }) => (
          <Typography sx={{ mt: 1, fontWeight: "bold" }}>{children}</Typography>
        ),
        h1: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h1"}>
            {children}
          </Typography>
        ),
        h2: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h2"}>
            {children}
          </Typography>
        ),
        h3: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h3"}>
            {children}
          </Typography>
        ),
        h4: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h4"}>
            {children}
          </Typography>
        ),
        h5: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h5"}>
            {children}
          </Typography>
        ),
        h6: ({ children }) => (
          <Typography gutterBottom sx={{ mt: 2 }} variant={"h6"}>
            {children}
          </Typography>
        ),

        // **********
        // * Tables *
        // **********
        table: ({ children }) => (
          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
              px: 2,
              py: 2,
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <Table size="small" sx={{ px: 3, py: 3 }}>
              {children}
            </Table>
          </TableContainer>
        ),
        tbody: ({ children }) => (
          <TableBody sx={{ px: 2, py: 2 }}>{children}</TableBody>
        ),
        // th: ({ children }) => (<TableHead>{children}</TableHead>),
        tr: ({ children }) => (
          <TableRow sx={{ px: 2, py: 2 }}>{children}</TableRow>
        ),
        td: ({ children }) => (
          <TableCell sx={{ px: 2, py: 2 }}>
            <Typography>{children}</Typography>
          </TableCell>
        ),

        // *********
        // * Lists *
        // *********
        ol: ({ children }) => (
          <List
            sx={{
              listStyleType: "decimal",
              mt: 2,
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {children}
          </List>
        ),
        ul: ({ children }) => (
          <List
            sx={{
              listStyleType: "disc",
              mt: 2,
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {children}
          </List>
        ),
        li: ({ children, ...props }) => (
          <ListItem sx={{ m: 0, p: 0, ml: 2 }} disableGutters>
            <ListItemText sx={{ pl: 0.25 }}>{children}</ListItemText>
          </ListItem>
        ),
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
}
