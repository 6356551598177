import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import BillingPlan from "./billing-plan";
import PaykickstartEmbed from "./paykickstart-embed";

const initialValues = {
  customerEmail: "",
  customerName: "",
  submit: null,
};

type Props = {
  open: boolean;
  onClose: () => void;
  processor?: string | null;
};

const BILLING_EMAIL = "support@wordform.ai";

export const NoCreditsLeftDialog = (props: Props) => {
  const { open = false, onClose, processor, ...other } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    //   }}
    >
      <DialogTitle>Oops... You Are Out of Credits!</DialogTitle>
      <DialogContent>
        Hello, according to our records, you no longer have any more credits
        available. Have questions? You can contact our billing department at:{" "}
        <b>{BILLING_EMAIL} </b>. Or you can purchase more credits below.
        <Stack sx={{ p: 2 }}>
          {processor === "paykickstart" && <PaykickstartEmbed />}
          {processor !== "paykickstart" && <BillingPlan />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
