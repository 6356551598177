import {
  Box,
  Container,
  Stack,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import NichePigResultsTable from "./results-table";

const NichePigResults = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationData = useSelector((state: RootState) => state.integrations);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
          }}
        ></Box>


        <Typography align="center" variant="h3" sx={{ pt: 2 }}>
          Your Results Are In!
        </Typography>
        <Typography align="center" color="text.secondary" variant="body2">
          Niche Pig Pro Has Successfully Found Several Profitable Niches For
          You!
        </Typography>
        <Stack sx={{ my: 2 }}>
          <NichePigResultsTable />
        </Stack>
      </Container>
    </>
  );
};

export default NichePigResults;
