import {
  Box,
  Grid
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import ToolsCard from "./tools-card";
import toolsData from "./tools-data";

type Props = {};

const ProjectsList = (props: Props) => {
  const dispatch = useAppDispatch();
  const authData = useSelector((state: RootState) => state.auth);
  const allToolKeys = authData.tools ? Object.keys(authData.tools) : [];
  const activeTools = allToolKeys.filter(
    (key) => authData.tools && authData.tools[key] !== null
  );

  return (
    <Box sx={{ my: 3 }}>
      <Grid container spacing={2} alignItems="stretch">
        {toolsData.map((tool, k) => {
          const canPurchase =
            tool.dependents.length === 0 ||
            tool.dependents.filter((t) => activeTools.includes(t)).length ===
            tool.dependents.length;

          return (
            <React.Fragment key={k}>
              <ToolsCard
                tool={tool}
                purchased={tool.free || activeTools.includes(tool.key)}
                canPurchase={canPurchase}
              />
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProjectsList;
