import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../common/layouts/auth";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { authSlice } from "../auth";
import * as Sentry from "@sentry/react";

type Inputs = {
  email: string;
  password: string;
  stayLoggedIn: boolean;
};

const Login = () => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<Inputs>({
    mode: "onChange",
  });
  useEffect(() => {
    document.title = "Wordfrom AI - Login";
  }, []);
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    data.password = data.password.trim();
    data.email = data.email.trim();

    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/user/login/`, data);
      const user = response.data.result;
      dispatch(authSlice.actions.setUser(user));
      toast.success("Logged in successfully!");
      try {
        // @ts-ignore
        posthog.identify(user.id, {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          createdAt: user.createdAt,
        });
        // @ts-ignore
        Beacon("identify", {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        });
        // @ts-ignore
        Sentry.setUser({ email: user.email });
      } catch (e) {
        console.warn("Error identifying user in analytics", e);
      }
      navigate("/");
      reset();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error logging in...");
      }
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ mb: 3 }}>
          <Typography variant="h4">Login</Typography>
        </Stack>
        <Stack spacing={2}>
          <TextField
            autoFocus
            fullWidth
            label="Email address"
            type="email"
            error={errors.email != null}
            required
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.email && <FormHelperText error>Please enter a valid email address!</FormHelperText>}
          <TextField
            required
            fullWidth
            label="Password"
            type="password"
            error={errors.password != null}
            {...register("password", { required: true, minLength: 5 })}
          />
          {errors.password && <FormHelperText error>Please enter a valid password!</FormHelperText>}
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          loading={loading}
          sx={{ mt: 4 }}
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          Login
        </LoadingButton>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Button href={"/reset"}>Forgot password</Button>
        </Box>
      </form>
    </AuthLayout>
  );
};

export default Login;
