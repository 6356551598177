import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import {
  Box,
  Button,
  IconButton,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useAppDispatch } from "../../core/hooks/useStore";
import { useDialog } from "../../hooks/use-dialog";
import { DeleteIntegrationDialog } from "./delete-integration-dialog";

type IntegrationsCardProps = {
  id: string;
  domain: string;
};

const IntegrationsCard = (props: IntegrationsCardProps) => {
  const dispatch = useAppDispatch();
  const deleteIntegrationDialog = useDialog();

  const fetchData = async () => {
    try {
      setStatusButton(
        <IconButton size="small" disabled>
          <CircularProgress
            size={24}
          />
        </IconButton>,
      )
      setIntegrationStatus("Testing credentials...");

      const response = await axios.post(`/api/integration/get`, {
        id: props.id,
        validate: true,
      });
      const { status, error } = response.data.result.data;
      if (status) {
        setStatusButton(
          integrationStatusButton(
            <CheckCircleIcon
              sx={{
                color: "green",
              }}
            />
          ),
        );
        setIntegrationStatus("WordPress API is working and authorized");
      } else {
        setStatusButton(
          integrationStatusButton(
            <SyncProblemIcon
              sx={{
                color: "red",
              }}
            />
          ),
        );
        setIntegrationStatus(error);
      }
    } catch (error) {
      setStatusButton(
        integrationStatusButton(
          <SyncProblemIcon
            sx={{
              color: "red",
            }}
          />
        ),
      );
      setIntegrationStatus("Integration not working");
    }
  };

  const [integrationStatus, setIntegrationStatus] = useState("Click to check connection...");
  const [statusButton, setStatusButton] = useState(
    <Button
      size="small"
      color="primary"
      variant="outlined"
      onClick={fetchData}
    >
      Test Connection
    </Button>
);

  const integrationStatusButton = (icon: any) => {
    return (
      <IconButton
        size="small"
        onClick={fetchData}
      >
        {icon}
      </IconButton>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card key={props.id}>
        <CardMedia
          sx={{ height: 140 }}
          component="img"
          image={`https://s.wordpress.com/mshots/v1/${props.domain}`}
          title="WordPress"
        />
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            WordPress
          </Typography>
          <Typography variant="h5" component="div">
            {props.domain}
          </Typography>
          Your site has been successfully connected to Wordform AI.
        </CardContent>
        <Divider />
        <CardActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{display: "relative",}}>
              <Tooltip title={integrationStatus} placement="top" arrow>
                {statusButton}
              </Tooltip>
            </Box>
            <Button
              size="small"
              onClick={deleteIntegrationDialog.handleOpen}
              variant="text"
              color="secondary"
            >
              Disconnect
            </Button>
          </Box>
        </CardActions>
      </Card>
      <DeleteIntegrationDialog
        onClose={deleteIntegrationDialog.handleClose}
        open={deleteIntegrationDialog.open}
        integrationName={props.domain}
        integrationID={props.id}
      />
    </Grid>
  );
};

export default IntegrationsCard;
