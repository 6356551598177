import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import {html as beautify_html} from "js-beautify";


type Props = {
  open: boolean;
  onClose: any;
  code: string;
  onUpdateCode: (code: string) => void;
  loading?: boolean;
};

export const EditCodeDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const editorState = useSelector((state: RootState) => state.editor);
  const [code, setCode] = useState(beautify_html(props.code));
  useEffect(() => {
    if (props.code) {
      setCode(beautify_html(props.code));
    }
  }, [open]);
  const handleSubmitDialog = () => {
    props.onUpdateCode(code);
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle>
        Edit Code
        <DialogContentText>
          Edit the html of the article directly.
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <CodeEditor
          value={code}
          language="html"
          placeholder="Please enter HTML code"
          onChange={(evn) => setCode(evn.target.value)}
          padding={15}
          style={{
            backgroundColor: "#fff",
            fontFamily:
              "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            minHeight: "350px",
            fontSize: "15px",
          }}
          data-color-mode="light"
          disabled={props.loading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={props.onClose}
          disabled={props.loading}
        >
          Close
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmitDialog}
          disabled={props.loading}
        >
          Update Code
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
