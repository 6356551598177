import { Box, Button, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import NotFoundIcon from "../error/illustration-not-found.svg";

const ProjectsEmpty = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationData = useSelector((state: RootState) => state.integrations);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
          }}
        ></Box>
        <img src={NotFoundIcon} alt="You Haven't Created a Project Yet!" />
        <Typography align="center" variant="h3" sx={{ pt: 2 }}>
          You Haven't Created a Project Yet!
        </Typography>
        <Typography align="center" color="text.secondary" variant="body2">
          Projects are the first step to creating content. Click the create
          button now to create your first project.
        </Typography>
        <Button onClick={props.dialog} sx={{ mt: 2 }}>
          Create a Project
        </Button>
      </Container>
    </>
  );
};

export default ProjectsEmpty;
