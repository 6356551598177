import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";

const Home = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  // dispatch(homeSlice.actions.setActiveMenu("projects"))

  if (!user || !user.email) {
    return <Navigate to="/login" />;
  }

  //Return
  return <Navigate to="/projects" />;
};

export default Home;
