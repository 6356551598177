import PlusIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Stack,
  SvgIcon,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { editorSlice } from "..";
import { Scrollbar } from "../../../common/components/scrollbar";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { useDialog } from "../../../hooks/use-dialog";
import { ArticleDataSource } from "../../../interfaces/article.interface";
import useProject from "../panel/use-project";
import { CreateCustomSourceData, CreateCustomSourceDialog } from "./create-custom-source-dialog";
import { SourceDialogCard } from "./source-dialog-card";

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
  open: boolean;
  onClose: any;
  update: boolean;
};

type CreateArticleData = {
  keyword: string;
};

export const SelectSourcesDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);
  const createCustomSourceDialog = useDialog();
  const project = useProject(props.editorRef);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<CreateArticleData>({
    mode: "onChange",
  });

  const sources = editorState.article?.sources || [];
  const scrapedSources = editorState.article?.scraped_sources || [];
  const [visibleSources, setVisibleSources] = useState<Array<any>>(
    sources.concat(_.differenceBy(scrapedSources, sources, "_id")).slice(0, 20),
  );
  const [scrapeErrorMessage, setScrapeErrorMessage] = useState<string | null>(null);

  const updateSourcesAsync = async (skip?: boolean) => {
    try {
      setLoading(true);
      setScrapeErrorMessage(null);
      const sources = visibleSources?.filter((s: ArticleDataSource) => selected.includes(s._id as string));
      const response = await axios.post(`${apiUrl}/article/update-sources`, {
        id: editorState.article?.id,
        sources: skip ? [] : sources,
        status: editorState.article?.status === "created" ? "added_sources" : "",
      });
      const article = response.data.result;
      !skip && toast.success("Successfully added sources to article");

      dispatch(editorSlice.actions.setArticle(article));
      dispatch(editorSlice.actions.setAddSourcesDialogOpened({ opened: false }));
      project.refresh(article.projectId as string);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
        setScrapeErrorMessage(e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
      setLoading(false);
    }
    setLoading(false);
    reset();
  };

  const onSubmit: SubmitHandler<CreateArticleData> = async (data) => {
    updateSourcesAsync();
  };

  const onSkip = () => {
    updateSourcesAsync(true);
  };

  const [selected, setSelected] = useState<Array<any>>(sources.map((s: ArticleDataSource) => s._id));

  const addSelect = (_id: string) => {
    if (selected.length < 3) {
      const newSelected = [...selected];
      newSelected.push(_id);
      setSelected(newSelected);
    } else {
      toast.error("You can only select a maximum of 3 sources.");
    }
  };

  const removeSelect = (_id: string) => {
    setSelected(selected.filter((s) => s !== _id));
  };

  const handleCloseCreateCustomSourceDialog = () => {
    createCustomSourceDialog.handleClose();
  };

  const unshiftVisibleSources = (source: ArticleDataSource) => {
    const newVisibleSources = visibleSources;
    newVisibleSources.unshift(source);
    setVisibleSources(newVisibleSources);
  };

  const handleAddNewCustomSource = (data: CreateCustomSourceData) => {
    switch (data.type) {
      case "text":
        unshiftVisibleSources({
          _id: visibleSources.length.toString(),
          createdAt: new Date(),
          updatedAt: new Date(),
          title: data.title.trim(),
          type: "text",
          content: data.text,
          data: {
            desc: data.text?.substring(0, 100) + "...",
          },
        });
        break;
      case "url":
        const { hostname } = new URL(data.url as string);
        unshiftVisibleSources({
          _id: visibleSources.length.toString(),
          createdAt: new Date(),
          updatedAt: new Date(),
          title: data.title,
          type: "url",
          data: {
            domain: hostname,
            url: data.url,
          },
        });
        break;
    }
  };

  if (createCustomSourceDialog.open) {
    return (
      <CreateCustomSourceDialog
        open={true}
        onClose={handleCloseCreateCustomSourceDialog}
        onSubmit={handleAddNewCustomSource}
      />
    );
  }

  const handleCreateCustomSource = () => {
    createCustomSourceDialog.handleOpen();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ mb: "5px" }}>
          Select Sources
          <DialogContentText>Sources provide background knowledge for article generation.</DialogContentText>
          {scrapeErrorMessage && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {scrapeErrorMessage}
            </Alert>
          )}
        </DialogTitle>
        <Container maxWidth="sm">
          <Scrollbar
            sx={{
              height: "450px",
              overflowX: "hidden",
              "& .simplebar-content": {
                height: "100%",
              },
              my: "10px",
            }}
          >
            <Stack direction="row" justifyContent="center">
              <Button variant="text" onClick={handleCreateCustomSource} disabled={selected.length >= 3}>
                <SvgIcon fontSize="small">
                  <PlusIcon />
                </SvgIcon>
                Create Custom Source
              </Button>
            </Stack>
            {visibleSources.map((data: ArticleDataSource, k: number) => {
              const isSelected = selected.includes(data._id as string);
              return (
                <div key={k}>
                  <SourceDialogCard
                    data={data}
                    selected={isSelected}
                    onSelect={() => {
                      if (loading) return;
                      !isSelected ? addSelect(data._id as string) : removeSelect(data._id as string);
                    }}
                  />
                </div>
              );
            })}
          </Scrollbar>
        </Container>
        <DialogActions>
          <DialogContentText sx={{ flex: 1 }}>Currently Selected: {selected.length} / 3 sources</DialogContentText>
          {props.update && (
            <Button color="inherit" onClick={props.onClose} disabled={loading}>
              Cancel
            </Button>
          )}
          {!props.update && (
            <Button color="inherit" onClick={onSkip} disabled={loading}>
              Skip
            </Button>
          )}
          <LoadingButton loading={loading} type="submit" variant="contained" disabled={!formState.isValid}>
            {props.update ? "Update Sources" : `Update Sources`}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
