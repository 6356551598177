import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteOutlineOutlined from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import extractExcerpt from "./projects-card-excerpt";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Grid,
  Typography,
  Box,
  Link,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useDialog } from "../../hooks/use-dialog";
import { DeleteProjectDialog } from "./delete-project-dialog";
import defaultFeaturedImage from "./default-featured-image.webp";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../core/hooks/useStore";

type ProjectsCardProps = {
  id: string;
  name: string;
  key?: number;
  status?: string;
  content?: string | null;
  image?: string | null;
};

type StatusData = {
  text: string;
  generating?: boolean;
  completed?: boolean;
};

const STATUS_TEXT: { [key: string]: StatusData } = {
  created: {
    text: "Created Project",
  },
  added_sources: {
    text: "Added Sources",
  },
  updated_brand_voice: {
    // TODO: rename this status in DB to selected_integration
    text: "Updated Integration",
  },
  selected_integration: {
    text: "Selected Integration",
  },
  creating_outline: {
    text: "Generating Outline",
    generating: true,
  },
  created_outline: {
    text: "Created Outline",
  },
  creating_article: {
    text: "Creating Article",
    generating: true,
  },
  created_article: {
    text: "Created Article",
    completed: true,
  },
  published: {
    text: "Published",
    completed: true,
  },
};

const statusToChip = (status: string) => {
  const statusData = STATUS_TEXT[status];
  if (!statusData) {
    return <></>;
  }
  const { text, generating, completed } = statusData;
  const color = completed ? "success" : generating ? "primary" : "default";
  const icon = completed ? (
    <CheckCircleIcon />
  ) : generating ? (
    <CircularProgress size={10} thickness={6} />
  ) : (
    <TuneIcon />
  );
  return (
    <div>
      <Chip icon={icon} label={text} color={color} size="small" />
    </div>
  );
};

const MoreMenu = (props: ProjectsCardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteProjectDialog = useDialog();
  const handleDelete = () => {
    setAnchorEl(null);
    deleteProjectDialog.handleOpen();
  };
  return (
    <div>
      <DeleteProjectDialog
        onClose={deleteProjectDialog.handleClose}
        open={deleteProjectDialog.open}
        projectName={props.name}
        projectID={props.id}
      />
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem key="delete" onClick={handleDelete}>
          <DeleteOutlineOutlined color="secondary" fontSize="small" sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

const ProjectsCard = (props: ProjectsCardProps) => {
  const image = props.image ? props.image : defaultFeaturedImage;
  const status = props.status ? props.status : "created";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Link component={RouterLink} to={`/editor/${props.id}`}>
              <CardMedia
                sx={{
                  boxShadow: 3,
                }}
              >
                <Avatar
                  src={image}
                  alt={props.name}
                  sx={{
                    width: { xs: 90, md: 120 },
                    height: { xs: 90, md: 120 },
                  }}
                  variant="rounded"
                >
                  <img src={defaultFeaturedImage} alt={props.name} loading="lazy" />
                </Avatar>
              </CardMedia>
            </Link>

            <Link
              component={RouterLink}
              to={`/editor/${props.id}`}
              underline="none"
              sx={{ width: "100%", "&:hover .underline-on-hover": { textDecoration: "underline" } }}
            >
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{
                    alignItems: { lg: "center" },
                  }}
                >
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className="underline-on-hover"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {props.name}
                  </Typography>
                  {statusToChip(status)}
                </Stack>

                <Typography sx={{ fontSize: 16, mt: 0.5 }} color="text.secondary" gutterBottom>
                  {(props.content?.trim() && extractExcerpt(200, props.content)) || (
                    <Box sx={{ display: "flex", alignItems: "center", fontStyle: "italic" }}>No content</Box>
                  )}
                </Typography>
              </Box>
            </Link>
            <MoreMenu {...props} />
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProjectsCard;
