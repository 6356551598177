import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSlice } from "../../features/auth/auth";
import { RootState } from "../store/store";
import { useAppDispatch } from "./useStore";
import * as Sentry from "@sentry/react";

export function useUserData() {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();

  const onLogout = async () => {
    try {
      // @ts-ignore
      posthog.reset();
      // @ts-ignore
      Beacon('logout');
      // @ts-ignore
      Sentry.setUser(null);
    } catch (e) {
      console.warn("Error", e);
    }

    navigate("/");
    try {
      const response = await axios.get(`${apiUrl}/user/logout`);
      if (response.data?.success) {
        dispatch(authSlice.actions.setUser(false));
        toast.success("Logged out successfully!");
      } else {
        dispatch(authSlice.actions.setUser(false));
        toast.error("Logout failed!");
      }
    } catch (e: any) {
      navigate("/");
      if (e.response?.data?.error?.message) {
        toast.dismiss();
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error logging out...");
      }
    }
    dispatch(authSlice.actions.setUser({}));
  };

  const getUser = async () => {
    try {
      const resp = await axios.post(`${apiUrl}/user/get`);
      const result = resp.data.result;
      const user = result.user;
      const credit = result.credit;
      const imageLimit = result.imageLimit;
      const billing = result.billing;
      const subscriptions = result.subscriptions;
      const plan = result.plan;
      const tools = result.tools;

      dispatch(authSlice.actions.setUser(user));
      dispatch(authSlice.actions.setCredits(credit));
      dispatch(authSlice.actions.setBilling(billing));
      dispatch(authSlice.actions.setImageLimit(imageLimit));
      dispatch(authSlice.actions.setSubscriptions(subscriptions));
      dispatch(authSlice.actions.setPlan(plan));
      dispatch(authSlice.actions.setTools(tools));

      if (
        !subscriptions?.subscriptions ||
        subscriptions.subscriptions.length === 0 ||
        subscriptions.subscriptions.filter((s: any) => s.status === "active")
          .length === 0
      ) {
        navigate("/billing");
      }
    } catch (err: any) {
      dispatch(authSlice.actions.setUser({}));
      dispatch(authSlice.actions.setCredits({}));
      dispatch(authSlice.actions.setBilling({}));
      dispatch(authSlice.actions.setImageLimit({}));
      dispatch(authSlice.actions.setSubscriptions({}));
      dispatch(authSlice.actions.setPlan({}));
      dispatch(authSlice.actions.setTools({}));

      onLogout();
    }
  };

  return {
    getUser,
    onLogout,
  };
}
