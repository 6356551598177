import { Alert, Card, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PropertyListItem } from "../../common/components/property-list-item";
import DashboardLayout from "../../common/layouts/dashboard";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { BillingInactiveDialog } from "./billing-inactive-dialog";
import PaykickstartEmbed from "./paykickstart-embed";
import Subscriptions from "./subscriptions";
import Paddle from "./paddle";

const BILLING_EMAIL = "support@wordform.ai";

const Billing = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [loading, setLoading] = useState<boolean>(false);
  const activeSubNum = authState.subscriptions?.subscriptions
    ? authState.subscriptions?.subscriptions.filter((s) => s.status === "active").length
    : 0;

  useEffect(() => {
    document.title = "Wordfrom AI - Billing";
  }, []);


  if (authState.user?.processor === "paddle") {
    return <Paddle />;
  }

  return (
    <DashboardLayout active={"billing"} name="Billing">
      <Alert color="info" severity="info" sx={{ mt: 3, mb: 3 }}>
        To cancel your account, please send an email to <b>{BILLING_EMAIL}</b> with your cancellation request.
      </Alert>
      <Stack spacing={3}>
        <Card>
          <Grid container>
            <Grid xs={12} md={3}>
              <PropertyListItem
                align={"vertical"}
                component="div"
                label="Plan Selected"
                value={authState.plan?.selected?.name || "No Plan Selected"}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <PropertyListItem
                align={"vertical"}
                component="div"
                label="Articles Used"
                value={authState.credits?.used + " articles"}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <PropertyListItem
                align={"vertical"}
                component="div"
                label="Articles Available"
                value={authState.credits?.available + " articles"}
              />
            </Grid>
            <Grid xs={12} md={3}>
              {
                <PropertyListItem
                  align={"vertical"}
                  component="div"
                  label="Active Subscriptions"
                  value={activeSubNum + " Subscriptions"}
                />
              }
            </Grid>
          </Grid>
        </Card>
        <PaykickstartEmbed />
        {/* {authState.user?.processor !== "paykickstart" && <BillingPlan />} */}
        <Subscriptions />
      </Stack>

      <BillingInactiveDialog
        open={
          !authState.subscriptions?.subscriptions ||
          authState.subscriptions.subscriptions.length === 0 ||
          authState.subscriptions.subscriptions.filter((s) => s.status === "active").length === 0
        }
        processor={authState.user?.processor}
      />
    </DashboardLayout>
  );
};

export default Billing;
