import {
  Stack,
  TextField
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import projectsSlice from "./projects.slice";

const SearchProjects = () => {
  const dispatch = useAppDispatch();
  const nameFilter = useSelector(
    (state: RootState) => state.projects.filter?.nameFilter
  );

  const handleNameFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(projectsSlice.actions.setNameFilter(event.target.value));
  };
  return (
    <>
      <Stack direction="row" alignItems="center">
        <TextField
          placeholder="Search..."
          onChange={handleNameFilterChange}
          value={nameFilter}
        />
      </Stack>
    </>
  );
};

export default SearchProjects;
