import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NicheData {
  interest: string;
  niches: {
    niche: string;
    subniches: string[];
    products: string[];
    services: string[];
    searchPotential: number;
    monetizationPotential: number;
    audiencePassion: number;
  }[];
}
interface KeywordData {
  interest: string;
}

interface HeadlineData {
  interest: string;
}

interface NicheJobData {
  _id?: string;
  createdAt: Date;
  finishedAt: Date;
  userId: string;
  type: "NICHE";
  data: NicheData;
}

interface KeywordJobData {
  _id?: string;
  createdAt: Date;
  finishedAt: Date;
  userId: string;
  type: "KEYWORD";
  data: KeywordData;
}

interface HeadlineJobData {
  _id?: string;
  createdAt: Date;
  finishedAt: Date;
  userId: string;
  type: "HEADLINE";
  data: HeadlineData;
}

type ToolJobType = "NICHE" | "HEADLINE" | "KEYWORD";

type ToolJobData = NicheJobData | KeywordJobData | HeadlineJobData;

interface ToolsState {
  tool: {
    type: ToolJobType;
    createDialogOpened: boolean;
    unlimitedDialogOpened: boolean;
    status: "loading" | "initialized" | "unauthorized" | "creating" | "created";
    data: ToolJobData | null;
  };
}

const initialState: ToolsState = {
  tool: {
    type: "NICHE",
    createDialogOpened: false,
    unlimitedDialogOpened: false,
    status: "loading",
    data: null,
  },
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setCreateDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.tool.createDialogOpened = action.payload;
    },
    setUnlimitedDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.tool.unlimitedDialogOpened = action.payload;
    },
    setData: (state, action: PayloadAction<ToolJobData | null>) => {
      state.tool.data = action.payload;
    },
    setStatus: (
      state,
      action: PayloadAction<
        "loading" | "initialized" | "unauthorized" | "creating" | "created"
      >
    ) => {
      state.tool.status = action.payload;
    },
    setType: (state, action: PayloadAction<ToolJobType>) => {
      state.tool.type = action.payload;
    },
  },
});

export type {
  HeadlineJobData, KeywordJobData, NicheJobData, ToolJobData,
  ToolJobType, ToolsState
};
export const { } = toolsSlice.actions;
export default toolsSlice;
