import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useState } from "react";
import generateArticleImage from "./generate-article.png";

type Props = {
  open: boolean;
  onClose: any;
};

type CreateArticleData = {
  keyword: string;
};

export const CreateArticleInfoDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <img
        src={generateArticleImage}
        width="200px"
        style={{ alignSelf: "center", marginTop: "25px", marginBottom: "0" }}
      />
      <DialogTitle>
        Next Step - Generate the Article
        <DialogContentText>
          Your outline has been generated! Here's the next step...
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        Edit your outline to make it perfect. Then, once you're done, click the
        "Generate Article" button at the top.
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" onClick={props.onClose}>
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
