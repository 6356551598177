import { Container, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import { Loader } from "../../components/loader";
import { Footer } from "./footer";
import { SideNav } from "./side-nav";
import TopNav from "./top-nav";
const SIDE_NAV_WIDTH = 270;
const TOP_NAV_HEIGHT = 64;

export const DashboardLayout = (props: any) => {
  const authState = useSelector((state: RootState) => state.auth);

  const { children, active } = props;

  const useMobileNav = () => {
    const [open, setOpen] = useState(false);

    const handlePathnameChange = useCallback(() => {
      if (open) {
        setOpen(false);
      }
    }, [open]);

    const handleOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);

    return {
      handleClose,
      handleOpen,
      open,
    };
  };

  const LayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    paddingTop: TOP_NAV_HEIGHT,
    paddingLeft: SIDE_NAV_WIDTH,
    [theme.breakpoints.up("md")]: {
      paddingLeft: SIDE_NAV_WIDTH,
    },
  }));

  const LayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
  });

  const ChildWrapper = styled("div")({
    minHeight: "90vh",
    marginTop: "30px",
    marginRight: "25px",
  });

  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const mobileNav = useMobileNav();

  const offset = SIDE_NAV_WIDTH;

  if (!authState.user) {
    return <Loader />;
  }

  return (
    <>
      <TopNav
        name=""
        plan=""
        onNavOpen={mobileNav.handleOpen}
        openNav={mobileNav.open}
      />
      {!mdDown && <SideNav active={props.active} />}
      <LayoutRoot
        sx={{
          pl: {
            xs: 0,
            md: offset + "px",
          },
        }}
      >
        <LayoutContainer>
          <ChildWrapper>
            <Container>{children}</Container>
          </ChildWrapper>
          <Footer />
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default DashboardLayout;
