import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../common/components/loader";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import ProjectsCard from "./projects-card";
import ProjectsEmpty from "./projects-empty";
import projectsSlice from "./projects.slice";

type Props = {};

let cancelToken: any;
const ProjectsList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const projects = useSelector((state: RootState) => state.projects.projects);
  const projectsCount = useSelector((state: RootState) => state.projects.projectsCount);
  const projectsFilteredCount = useSelector((state: RootState) => state.projects.projectsFilteredCount);
  const page = useSelector((state: RootState) => state.projects.page);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(projectsSlice.actions.setPage(value));
  };
  const filter = useSelector((state: RootState) => state.projects.filter);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const PAGE_SIZE = 24;

  const getProjects = async (pageSize: number) => {
    try {
      setLoading(true);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      const data = {
        pageSize,
        page,
        nameFilter: filter.nameFilter,
        integrationFilter: filter.integrationFilter,
      };
      // Cancel the previous request
      // Create a new cancel token
      const response: any = await axios.post(`${apiUrl}/project/list`, data, {
        cancelToken: cancelToken.token,
      });
      const result = response.data.result;
      dispatch(projectsSlice.actions.setProjects(result.projects));
      dispatch(projectsSlice.actions.setProjectsCount(result.count));
      dispatch(projectsSlice.actions.setProjectsFilteredCount(result.filteredCount));
      setLoading(false);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      }
    }
  };

  const debounceGetProjects = _.debounce(getProjects, 500);

  useEffect(() => {
    debounceGetProjects(PAGE_SIZE);
  }, [page, filter]);

  if (loading) {
    return <Loader />;
  }

  const handleOpenCreateDialog = () => {
    dispatch(projectsSlice.actions.setCreateDialogOpened(true));
  };

  return (
    <>
      {projectsCount === 0 && <ProjectsEmpty dialog={handleOpenCreateDialog} />}
      {projectsCount > 0 && (
        <>
          <Box sx={{ mt: 3 }}>
            <Stack spacing={2}>
              {projects.map((project) => {
                return (
                  <React.Fragment key={project.id}>
                    <ProjectsCard
                      id={project.id}
                      name={project.name}
                      status={project.article?.status}
                      content={project.article?.content}
                      image={project.featuredImage}
                    />
                  </React.Fragment>
                );
              })}
            </Stack>
            <Stack sx={{ mt: 2, pl: 1 }}>
              <Typography color="textSecondary" variant="body2">
                Showing {projects.length} of {projectsFilteredCount} projects
              </Typography>
            </Stack>
          </Box>
          <Stack sx={{ mt: 2, mb: 4 }} justifyContent="center" alignItems="center">
            <Pagination
              count={Math.ceil(projectsFilteredCount / PAGE_SIZE)}
              variant="outlined"
              shape="rounded"
              size="large"
              onChange={handlePageChange}
              page={page}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default ProjectsList;
