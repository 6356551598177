import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import axios from "axios";
import copy from "copy-to-clipboard";
import React, { RefObject, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { NoCreditsLeftDialog } from "../../../features/billing/no-credits-left-dialog";
import { editorSlice } from "../../../features/editor";
import { MAIN_BUTTON_ACTIONS } from "../../../features/editor/editor/editor.slice";
import useProject from "../../../features/editor/panel/use-project";

type Props = {
  editorRef: RefObject<any>;
};

export const PanelButton = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [noCreditsOpen, setNoCreditsOpen] = useState<boolean>(false);
  const editorState = useSelector((state: RootState) => state.editor);
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const options = editorState.mainButtonActions;
  const disabled = editorState.mainButtonDisabled;
  const loading = editorState.article?.generating;
  const project = useProject(props.editorRef);
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const generateOutline = async () => {
    try {
      dispatch(editorSlice.actions.setTransitionLoading("Gathering Background Information..."));
      const data = {
        id: editorState.article?.id,
      };
      const response: any = await axios.post(`${apiUrl}/article/generate-outline`, data);

      dispatch(editorSlice.actions.setTransitionLoading(null));
      dispatch(editorSlice.actions.setGenerating(true));
      project.refresh(editorState.project?.id as string);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error generating outline...");
      }
    }
  };

  const generateArticle = async () => {
    try {
      dispatch(editorSlice.actions.setTransitionLoading("Gathering Background Information..."));
      const data = {
        id: editorState.article?.id,
      };

      dispatch(editorSlice.actions.setTransitionLoading(null));
      dispatch(editorSlice.actions.setGenerating(true));
      project.refresh(editorState.project?.id as string);
      dispatch(editorSlice.actions.setPublishDialogOpened(false));
      const response: any = await axios.post(`${apiUrl}/article/generate-article`, data);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error generating article...");
      }
    }
  };

  const stopGenerating = async () => {
    try {
      dispatch(editorSlice.actions.setTransitionLoading("Stopping article generation..."));
      const data = {
        id: editorState.article?.id,
      };
      const response: any = await axios.post(`${apiUrl}/article/stop-generating`, data);
      dispatch(editorSlice.actions.setTransitionLoading(null));
      dispatch(editorSlice.actions.setGenerating(false));
      project.refresh(editorState.project?.id as string);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error generating article...");
      }
    }
  };

  const newArticle = async () => {
    try {
      dispatch(editorSlice.actions.setArticleDialogOpened(true));
      const data = {
        id: editorState.article?.id,
      };
      const response: any = await axios.post(`${apiUrl}/article/generate-article`, data);

      dispatch(editorSlice.actions.setTransitionLoading(null));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error generating article...");
      }
    }
  };

  const publishArticle = () => {
    dispatch(editorSlice.actions.setPublishDialogOpened(true));
  };

  const copyArticle = () => {
    copy(editorState.localArticle?.content || "", { format: "text/html" });
    toast.success("Article copied to clipboard");
  };

  const shareArticle = () => {
    dispatch(editorSlice.actions.setShareArticleDialogOpened(true));
  };

  const handlePanelButtonClick = async (option: string) => {
    switch (option) {
      case MAIN_BUTTON_ACTIONS.generateOutline:
        if (!authState.billing?.creditsAvailable) {
          setNoCreditsOpen(true);
          return;
        }
        generateOutline();
        break;
      case MAIN_BUTTON_ACTIONS.regenerateOutline:
        if (!authState.billing?.creditsAvailable) {
          setNoCreditsOpen(true);
          return;
        }
        generateOutline();
        break;
      case MAIN_BUTTON_ACTIONS.generateArticle:
        if (!authState.billing?.creditsAvailable) {
          setNoCreditsOpen(true);
          return;
        }
        generateArticle();
        break;
      case MAIN_BUTTON_ACTIONS.regenerateArticle:
        if (!authState.billing?.creditsAvailable) {
          setNoCreditsOpen(true);
          return;
        }
        await project.refresh(editorState.project?.id as string);
        dispatch(editorSlice.actions.setPublishDialogOpened(false));
        generateArticle();
        break;
      case MAIN_BUTTON_ACTIONS.publish:
        publishArticle();
        break;
      case MAIN_BUTTON_ACTIONS.stopGenerating:
        dispatch(editorSlice.actions.setGenerating(false));
        stopGenerating();
        break;
      case MAIN_BUTTON_ACTIONS.copyArticle:
        copyArticle();
        break;
      case MAIN_BUTTON_ACTIONS.shareArticle:
        shareArticle();
        break;
      // case MAIN_BUTTON_ACTIONS.newArticle:
      //   newArticle();
      //   break;
      default:
        break;
    }
  };

  const handleClick = () => {
    handlePanelButtonClick(options[selectedIndex]);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    handlePanelButtonClick(options[index]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseNoCredits = () => {
    setNoCreditsOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} disabled={disabled}>
        <Button onClick={handleClick} size="large">
          {loading && <CircularProgress size={15} sx={{ mr: "10px" }} color="inherit" />}
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          // width: "100%"
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <NoCreditsLeftDialog open={noCreditsOpen} onClose={handleCloseNoCredits} />
    </React.Fragment>
  );
};
