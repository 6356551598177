import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { editorSlice } from "..";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

type Props = {
  open: boolean;
  onClose: any;
  update?: boolean;
};

type Data = {
  privacy: "public" | "private";
};

export const ShareDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const editorState = useSelector((state: RootState) => state.editor);

  const updateArticlePrivacy = async (data: Data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/article/update`, {
        id: editorState.article?.id,
        privacy: data.privacy,
      });
      data.privacy === "public"
        ? toast.success(`Successfully enabled public sharing`)
        : toast.success(`Successfully disabled public sharing`);
      dispatch(editorSlice.actions.setArticle(response.data.result));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating article");
      }
    }
  };

  const handleShareToggle = (e: any) => {
    updateArticlePrivacy({ privacy: e.target.checked ? "public" : "private" });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>
        Share This Article
        <DialogContentText>
          <Typography variant="body1">Share a preview of this article with your team or clients.</Typography>
          <br />
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack direction="column" spacing={1}>
            <TextField
              fullWidth
              label="Share Link"
              onFocus={(event) => {
                event.target.select();
              }}
              value={apiUrl.split("/api").join("") + "/share/" + editorState.article?.id}
              disabled={loading || editorState.article?.privacy !== "public"}
            />
            <FormControlLabel
              control={<Switch />}
              checked={editorState.article?.privacy === "public"}
              label="Share Public Link"
              disabled={loading}
              onChange={handleShareToggle}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} type="submit" variant="contained" onClick={props.onClose}>
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
