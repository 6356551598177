import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import projectsSlice from "./projects.slice";

type Props = {
  projectName: string;
  projectID: string;
  open: boolean;
  onClose: any;
};

export const DeleteProjectDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const getProjects = async () => {
    try {
      setLoading(true);
      const data = {};
      const response: any = await axios.post(`${apiUrl}/project/list`, data);
      const result = response.data.result;
      dispatch(projectsSlice.actions.setProjects(result.projects));
      dispatch(projectsSlice.actions.setProjectsCount(result.count));
      dispatch(projectsSlice.actions.setPage(1));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing projects...");
      }
    }
    setLoading(false);
  };

  const deleteProject = async () => {
    try {
      setLoading(true);
      const data = {};
      const response: any = await axios.post(`${apiUrl}/project/delete`, {
        id: props.projectID,
      });
      const result = response.data.result;
      await getProjects();
      props.onClose();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error deleting project...");
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      //   }}
    >
      <DialogTitle>Delete Project: {props.projectName}</DialogTitle>
      <DialogContent>Are you sure you want to delete the project, {props.projectName}?</DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
        <LoadingButton loading={loading} onClick={deleteProject} variant="contained">
          Delete Now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
