import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LockIcon from "@mui/icons-material/Lock";
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import { headlinePigSlice } from "../../headline-pig";
import { keywordPigSlice } from "../../keyword-pig";

const ResultsMoreButton = (props: { niche: string }) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const auth = useSelector((state: RootState) => state.auth);
  const hasHeadlineAccess = auth.tools?.headlineTool;
  const hasKeywordAccess = auth.tools?.keywordTool;

  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerateKeywords = () => {
    navigate("/tools/keyword-tool");
    dispatch(keywordPigSlice.actions.setCreateDialogOpened(true));
    dispatch(keywordPigSlice.actions.setDefaultSearch(props.niche));
    handleClose();
  };

  const handleGenerateHeadlines = () => {
    navigate("/tools/headline-tool");
    dispatch(headlinePigSlice.actions.setCreateDialogOpened(true));
    dispatch(headlinePigSlice.actions.setDefaultSearch(props.niche));
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        Generate
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleGenerateKeywords} disabled={!hasKeywordAccess}>
          {!hasKeywordAccess && (
            <ListItemIcon style={{ minWidth: "15px" }}>
              <LockIcon />
            </ListItemIcon>
          )}
          Keywords
        </MenuItem>
        <MenuItem
          onClick={handleGenerateHeadlines}
          disabled={!hasHeadlineAccess}
        >
          {!hasHeadlineAccess && (
            <ListItemIcon style={{ minWidth: "15px" }}>
              <LockIcon />
            </ListItemIcon>
          )}
          Headlines
        </MenuItem>
      </Menu>
    </>
  );
};

export default ResultsMoreButton;
