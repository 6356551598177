import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

const HeadlinePigLocked = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationData = useSelector((state: RootState) => state.integrations);

  return <></>;
};

export default HeadlinePigLocked;
