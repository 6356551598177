import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authSlice } from "../../features/auth/auth";
import { paddleSlice } from "../../features/billing/paddle";
import { editorSlice } from "../../features/editor";
import { homeSlice } from "../../features/home";
import { integrationsSlice } from "../../features/integrations";
import { projectsSlice } from "../../features/projects";
import { headlinePigSlice } from "../../features/tools/headline-pig";
import { keywordPigSlice } from "../../features/tools/keyword-pig";
import { nichePigSlice } from "../../features/tools/niche-pig";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  home: homeSlice.reducer,
  auth: authSlice.reducer,
  paddle: paddleSlice.reducer,
  projects: projectsSlice.reducer,
  integrations: integrationsSlice.reducer,
  editor: editorSlice.reducer,
  nichePig: nichePigSlice.reducer,
  keywordPig: keywordPigSlice.reducer,
  headlinePig: headlinePigSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "home/setActiveMenu"],
      // },
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
