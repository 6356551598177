import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, FormHelperText, MenuItem, Stack, TextField } from "@mui/material";
import axios from "axios";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Loader } from "../../../common/components/loader";
import { Scrollbar } from "../../../common/components/scrollbar";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { integrationsSlice } from "../../integrations";
import editorSlice from "../editor/editor.slice";
import { PanelSettingsTemplate } from "./panel-settings-template";
import useProject from "./use-project";

type SettingsData = {
  id?: string;
  name: string;
  integrationId: string | null;
};

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
  onDirtyChange: (dirty: boolean) => void;
};

export const Settings = (props: Props) => {
  const editorState = useSelector((state: RootState) => state.editor);
  const integrationState = useSelector((state: RootState) => state.integrations);

  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const [settings, setSettings] = useState<{ [key: string]: any } | undefined>(editorState.project?.settings);
  const project = useProject(props.editorRef);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    formState,
  } = useForm<SettingsData>({
    mode: "onChange",
    defaultValues: {
      id: editorState.project?.id,
      name: editorState.project?.name,
      integrationId: editorState.project?.integrationId ? editorState.project?.integrationId : null,
    },
  });

  useEffect(() => {
    reset({
      id: editorState.project?.id,
      name: editorState.project?.name,
      integrationId: editorState.project?.integrationId || null,
    });
  }, [editorState, reset]);

  const getIntegrations = async () => {
    try {
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
  };

  const refreshData = async () => {
    setLoading(true);
    const response = await Promise.all([getIntegrations()]);
    setLoading(false);
  };
  useEffect(() => {
    refreshData();
  }, [editorState.article?.id]);

  const onSubmit: SubmitHandler<SettingsData> = async (data) => {
    try {
      setLoading(true);
      const body = {
        id: data.id,
        name: data.name,
        integrationId: data.integrationId ? data.integrationId : null,
      };
      const response = await axios.post(`${apiUrl}/project/update`, body);
      const project = response.data.result;
      dispatch(editorSlice.actions.setProject(project));
      toast.success("Successfully updated project!");
      reset();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error updating project");
      }
    }
    reset();
    setLoading(false);
  };

  const submitSettings = async (e?: any) => {
    if (e) e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/project/update-settings`, {
        id: editorState.project?.id,
        settings,
      });
      const projectData = response.data.result;
      dispatch(editorSlice.actions.setProject(projectData));
      toast.success("Successfully updated settings!");
      reset();
      dispatch(editorSlice.actions.setMainButtonDisabled(false));
      project.refresh(editorState.project?.id as string);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error updating settings");
      }
    }
    reset();
    setLoading(false);
  };

  return (
    <Scrollbar
      sx={{
        height: "100%",
        overflowX: "hidden",
        "& .simplebar-content": {
          height: "100%",
        },
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack sx={{ padding: "20px" }} justifyItems="center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Project Name"
                  {...register("name", {
                    required: true,
                    minLength: 1,
                    validate: {},
                  })}
                />
                {formState.errors.name && <FormHelperText error={true}>Please enter a project name!</FormHelperText>}
                <TextField
                  fullWidth
                  select
                  label="Integration"
                  {...register("integrationId", {})}
                  defaultValue={getValues("integrationId")}
                >
                  <MenuItem key={""} value={""}>
                    No Integration
                  </MenuItem>
                  {integrationState.integrations &&
                    integrationState.integrations.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.data.domain}
                      </MenuItem>
                    ))}
                </TextField>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="outlined"
                  disabled={!formState.isValid || !formState.isDirty}
                >
                  Update Project
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
          <Divider />
          <Stack sx={{ padding: "20px" }} justifyItems="center">
            <form onSubmit={submitSettings}>
              <Stack spacing={2}>
                {settings && (
                  <PanelSettingsTemplate
                    value={settings}
                    onChange={(value) => {
                      setSettings(value);
                      dispatch(editorSlice.actions.setMainButtonDisabled(true));
                      props.onDirtyChange(true);
                    }}
                  />
                )}
                <LoadingButton loading={loading} type="submit" variant="outlined">
                  Update Settings
                </LoadingButton>
                <Divider sx={{ marginBottom: "20px" }} />
              </Stack>
            </form>
          </Stack>
        </>
      )}
      <Divider />
    </Scrollbar>
  );
};
