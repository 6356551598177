import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { createOptions as createBaseOptions } from './base/create-options';
import { createOptions as createLightOptions } from './light/create-options';

export const createTheme = (config) => {
  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseOptions({
    }),
    // Options based on selected palette mode, color preset and contrast
    createLightOptions({
      colorPreset: config.colorPreset,
    }));

  theme = responsiveFontSizes(theme);

  return theme;
};
