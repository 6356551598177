import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import toolsSlice, { ToolJobData } from "./niche-pig.slice";
import useTool from "./use-niche-pig";

const NichePigRecentSearches = (props: { selected?: string }) => {
  const dispatch = useAppDispatch();
  const [selection, setSelection] = useState<string>("");
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [jobs, setJobs] = useState<ToolJobData[]>([]);
  const toolsState = useSelector((state: RootState) => state.nichePig);
  const tool = useTool();

  useEffect(() => {
    const getJobs = async () => {
      const jobs = await tool.listJobs("NICHE");
      if (jobs) {
        setJobs(jobs);
      } else {
        setJobs([]);
      }
    };
    getJobs();
  }, [toolsState.tool.status]);

  const exportOpen = Boolean(exportAnchorEl);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelection(event.target.value);
  };

  const handleSearchOpen = (job: ToolJobData) => {
    dispatch(toolsSlice.actions.setStatus("created"));
    dispatch(toolsSlice.actions.setData(job));
  };

  const handleBackButton = () => {
    dispatch(toolsSlice.actions.setStatus("initialized"));
  };

  return (
    <FormControl
      sx={{
        px: 4,
        minWidth: 120,
        maxWidth: "300px",
        width: "100%",
        fontSize: "1.25rem",
      }}
    >
      <Select
        value={props.selected ? props.selected : "-1"}
        label="Recent Searches"
        placeholder="Recent Searches"
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {},
          },
        }}
      >
        <MenuItem key={-1} value={"-1"} onClick={handleBackButton}>
          None Selected
        </MenuItem>
        {[...jobs]
          .filter((job) => job.data.interest)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map((job) => (
            <MenuItem
              key={job._id}
              value={job._id}
              onClick={() => {
                handleSearchOpen(job);
              }}
            >
              {job.data.interest}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default NichePigRecentSearches;
