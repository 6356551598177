import ArrowTopRightOnSquareIcon from "@heroicons/react/24/outline/ArrowTopRightOnSquareIcon";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import { Box, ButtonBase, Collapse, SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const SideNavItem = (props: any) => {
  const {
    active = false,
    children,
    collapse = false,
    depth = 0,
    external = false,
    icon,
    openImmediately = false,
    path,
    title,
  } = props;
  const [open, setOpen] = useState<boolean>(openImmediately);
  const navigate = useNavigate();

  const handleToggle = useCallback(() => {
    setOpen((prevOpen: boolean) => !prevOpen);
  }, []);

  // Branch

  if (children) {
    return (
      <li>
        <ButtonBase
          onClick={handleToggle}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 500,
            justifyContent: "flex-start",
            px: "6px",
            py: "12px",
            textAlign: "left",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "neutral.400",
              display: "inline-flex",
              flexGrow: 0,
              flexShrink: 0,
              height: 24,
              justifyContent: "center",
              width: 24,
            }}
          >
            {icon}
          </Box>
          <Box
            component="span"
            sx={{
              color: depth === 0 ? "text.primary" : "text.secondary",
              flexGrow: 1,
              fontWeight: "bold",
              fontSize: 14,
              mx: "12px",
              transition: "opacity 250ms ease-in-out",
              ...(collapse && {
                opacity: 0,
              }),
            }}
          >
            {title}
          </Box>
          <SvgIcon
            sx={{
              color: "neutral.500",
              fontSize: 16,
              transition: "opacity 250ms ease-in-out",
              ...(collapse && {
                opacity: 0,
              }),
            }}
          >
            {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </ButtonBase>
        <Collapse in={!collapse && open} unmountOnExit>
          {children}
        </Collapse>
      </li>
    );
  }

  // Leaf

  return (
    <li>
      <ButtonBase
        sx={{
          alignItems: "center",
          borderRadius: 2,
          display: "flex",
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: active ? "600" : "500",
          justifyContent: "flex-start",
          px: "10px",
          py: "15px",
          my: "5px",
          textAlign: "left",
          whiteSpace: "nowrap",
          width: "100%",
          "&:hover": {
            background: (theme) => theme.palette.grey[100],
          },
          color: active ? "neutral.800" : "neutral.500",
          background: (theme) => (active ? theme.palette.grey[100] : ""),
        }}
        onClick={() => {
          navigate(path);
        }}
      >
        <Box
          component="span"
          sx={{
            alignItems: "center",
            display: "inline-flex",
            flexGrow: 0,
            flexShrink: 0,
            height: 24,
            justifyContent: "center",
            width: 24,
            color: (theme) =>
              active ? theme.palette.primary.main : "neutral.500",
          }}
        >
          {icon}
        </Box>
        <Box
          component="span"
          sx={{
            flexGrow: 1,
            mx: "12px",
            transition: "opacity 250ms ease-in-out",
            ...(collapse && {
              opacity: 0,
            }),
          }}
        >
          {title}
        </Box>
        {external && (
          <SvgIcon
            sx={{
              color: "neutral.500",
              fontSize: 18,
              transition: "opacity 250ms ease-in-out",
              ...(collapse && {
                opacity: 0,
              }),
            }}
          >
            <ArrowTopRightOnSquareIcon />
          </SvgIcon>
        )}
      </ButtonBase>
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
  collapse: PropTypes.bool,
  depth: PropTypes.number,
  external: PropTypes.bool,
  icon: PropTypes.any,
  openImmediately: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};
