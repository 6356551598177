import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { authSlice } from "../auth/auth";
import PaykickstartEmbed from "./paykickstart-embed";

const initialValues = {
  customerEmail: "",
  customerName: "",
  submit: null,
};

type Props = {
  open: boolean;
  processor?: string | null;
};

const BILLING_EMAIL = "support@wordform.ai";

export const BillingInactiveDialog = (props: Props) => {
  const { open = false, processor, ...other } = props;
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user/logout`);
      if (response.data?.success) {
        toast.success("Logged out successfully!");
      } else {
        toast.error("Logout failed: " + response.data.error.message);
      }
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error logging out...");
      }
    }
    dispatch(authSlice.actions.setUser({}));
    navigate("/");
  };

  const onClickTools = () => {
    navigate("/tools");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      // onClose={onClose}
      open={open}
      //   }}
    >
      <DialogTitle>You Do Not Have An Active Wordform Subscription...</DialogTitle>
      <DialogContent>
        Hello, according to our records, you do not have an active Wordform subscription. Have questions? You can
        contact our billing department at: <b>{BILLING_EMAIL} </b>
        <Stack sx={{ p: 2 }}>
          {processor === "paykickstart" && <PaykickstartEmbed />}
          {processor !== "paykickstart" && (
            // <BillingPlan disableSelectExistingPlan />
            <>
              <p>
                You can learn more about how to get access to Wordform AI by going to:{" "}
                <a href="https://www.wordformlaunch.com">Wordform AI here</a>
              </p>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickTools}>
          Go To Tools
        </Button>
        <Button color="inherit" onClick={onLogout}>
          Logout Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};
