import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../core/store/store";
import { TemplateResponse } from "../../../interfaces/template.interface";
import useTool from "./use-keyword-pig";

type Props = {
  open: boolean;
  onClose: any;
  defaultInterest?: string;
};

type CreateJobData = {
  interest: string;
};

export const KeywordPigUnlimitedDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Array<TemplateResponse>>([]);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();
  const tool = useTool();

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Get Unlimited Credits</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
            my: 3,
          }}
        >
          {" "}
          {/* <img src={NichePigLogo} alt="Niche Pig Pro" width={"250px"} /> */}
        </Box>

        <Typography align="center" variant="h4" sx={{ my: 2 }}>
          Would You Like To Upgrade To An Unlimited Number of Credits?
        </Typography>
        <Typography align="center" color="text.primary" variant="body1">
          Sign Up For The Unlimited Plan To Get An Unlimited Number of Credits
          Now For Niche and Keyword Research
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          href="https://www.nichepigpro.com/unlimited"
        >
          Switch To Unlimited Credits
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
