import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import { NicheJobData } from "../niche-pig.slice";
import ResultsMoreButton from "./results-more-button";

const NichePigResultsTable = (props: { hideButtons?: boolean }) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const toolData = useSelector((state: RootState) => state.nichePig);

  const results = toolData.tool.data
    ? (toolData.tool.data as NicheJobData).data.niches
    : null;

  if (!results) {
    return <></>;
  }

  const getCircularProgressBar = (num: number) => {
    const getColor = (num: number): string => {
      if (num > 0 && num < 3) {
        return "#d93452";
      } else if (num >= 4 && num < 7) {
        return "#fa704e";
      } else if (num >= 7 && num < 8) {
        return "#fdc509";
      } else {
        return "#34c176";
      }
    };
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={Math.ceil((num / 10) * 100)}
          sx={{ color: getColor(num) }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color={"black"}>
            {num.toFixed(1)}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Stack>
      <TableContainer>
        <Table sx={{ width: 1000 }} aria-label="results table">
          <TableHead>
            <TableRow>
              <TableCell>Niches</TableCell>
              <TableCell>Subniches</TableCell>
              <TableCell>Affiliate Products</TableCell>
              <TableCell>Services</TableCell>
              <TableCell>Search Potential (1-10)</TableCell>
              <TableCell>Monetization Potential (1-10)</TableCell>
              <TableCell>Audience Passion (1-10)</TableCell>
              <TableCell>Final Score (1-10)</TableCell>
              {!props.hideButtons && <TableCell>Generate </TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {[...results]
              .sort((a, b) => {
                return (
                  (b.audiencePassion +
                    b.monetizationPotential +
                    b.searchPotential) /
                  3 -
                  (a.audiencePassion +
                    a.monetizationPotential +
                    a.searchPotential) /
                  3
                );
              })
              .map((result) => {
                return (
                  <TableRow
                    key={1}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {result.niche}
                    </TableCell>
                    <TableCell align="left">
                      {result.subniches.join(", ")}
                    </TableCell>
                    <TableCell align="left">
                      {result.products.join(", ")}
                    </TableCell>
                    <TableCell align="left">
                      {result.services.join(", ")}
                    </TableCell>
                    <TableCell align="center">
                      {getCircularProgressBar(Number(result.searchPotential))}
                    </TableCell>
                    <TableCell align="center">
                      {getCircularProgressBar(
                        Number(result.monetizationPotential)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {getCircularProgressBar(Number(result.audiencePassion))}
                    </TableCell>
                    <TableCell align="left">
                      {getCircularProgressBar(
                        (Number(result.searchPotential) +
                          Number(result.monetizationPotential) +
                          Number(result.audiencePassion)) /
                        3
                      )}
                    </TableCell>
                    {!props.hideButtons && (
                      <TableCell>
                        <ResultsMoreButton niche={result.niche} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter sx={{ my: 4 }}></TableFooter>
    </Stack>
  );
};

export default NichePigResultsTable;
