import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";

const NichePigLocked = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationData = useSelector((state: RootState) => state.integrations);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
          }}
        ></Box>

        <SvgIcon>
          <LockIcon />
        </SvgIcon>

        <Typography align="center" variant="h3" sx={{ pt: 2 }}>
          Find Your Next Profitable Niche With Niche Pig Pro!
        </Typography>
        <Typography align="center" color="text.secondary" variant="body2">
          Projects are the first step to creating content. Click the create
          button now to create your first project.
        </Typography>
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={props.dialog}
            sx={{ mt: 2, mx: 2 }}
            href="https://www.nichepigpro.com"
          >
            Upgrade To Niche Pig Pro Now
          </Button>
          <Button
            onClick={props.dialog}
            sx={{ mt: 2 }}
            href="https://wordform.ai/niche-generator/"
            target="_blank"
          >
            Go to Niche Pig
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default NichePigLocked;
