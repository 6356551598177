import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { CreateIntegrationDialog } from "../../integrations/create-integration-dialog";
import { CreateArticleDialog } from "../dialog/create-article-dialog";
import { CreateArticleInfoDialog } from "../dialog/create-article-info-dialog";
import { CreateOutlineInfoDialog } from "../dialog/create-outline-info-dialog";
import { PublishArticleDialog } from "../dialog/publish-article-dialog";
import { PublishInfoDialog } from "../dialog/publish-info-dialog";
import { SelectSourcesDialog } from "../dialog/select-sources-dialog";
import { ShareDialog } from "../dialog/share-dialog";
import { UpdateIntegrationDialog } from "../dialog/update-integration-dialog";
import useProject from "../panel/use-project";
import editorSlice from "./editor.slice";

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
  id: string;
};

export const EditorDialogs = (props: Props) => {
  const dispatch = useAppDispatch();
  const project = useProject(props.editorRef);

  const updateArticleDialogOpened = useSelector(
    (state: RootState) => state.editor.updateArticleDialogOpened
  );
  const updateIntegrationDialogOpened = useSelector(
    (state: RootState) => state.editor.updateIntegrationDialogOpened
  );
  const addSourcesDialogOpened = useSelector(
    (state: RootState) => state.editor.addSourcesDialogOpened
  );
  const createOutlineInfoDialogOpened = useSelector(
    (state: RootState) => state.editor.createOutlineInfoDialogOpened
  );
  const createArticleInfoDialogOpened = useSelector(
    (state: RootState) => state.editor.createArticleInfoDialogOpened
  );
  const publishInfoDialogOpened = useSelector(
    (state: RootState) => state.editor.publishInfoDialogOpened
  );
  const createArticleDialogOpened = useSelector(
    (state: RootState) => state.editor.createArticleDialogOpened
  );
  const shareArticleDialogOpened = useSelector(
    (state: RootState) => state.editor.shareArticleDialogOpened
  );
  const createIntegrationDialogOpened = useSelector(
    (state: RootState) => state.editor.createIntegrationDialogOpened
  );
  const publishDialogOpened = useSelector(
    (state: RootState) => state.editor.publishDialogOpened
  );
  const handleCloseArticleDialog = () => {
    dispatch(editorSlice.actions.setArticleDialogOpened(false));
    dispatch(editorSlice.actions.setUpdateArticleDialogOpened(false));
  };

  const handleCloseUpdateIntegrationDialog = () => {
    dispatch(editorSlice.actions.setUpdateIntegrationDialogOpened(false));
    project.refresh(props.id as string);
  };
  const [createIntegrationOpened, setCreateIntegrationOpened] =
    useState<boolean>(false);
  const handleCreateIntegration = () => {
    dispatch(editorSlice.actions.setUpdateIntegrationDialogOpened(false));
    setCreateIntegrationOpened(true);
  };

  const handleCloseCreateIntegration = () => {
    dispatch(editorSlice.actions.setUpdateIntegrationDialogOpened(true));
    setCreateIntegrationOpened(false);
  };

  const handleCloseAddSourcesDialog = () => {
    dispatch(editorSlice.actions.setAddSourcesDialogOpened({ opened: false }));
  };

  const handleCloseOutlineInfoDialog = () => {
    dispatch(editorSlice.actions.setCreateOutlineInfoDialogOpened(false));
  };

  const handleCloseArticleInfoDialog = () => {
    dispatch(editorSlice.actions.setCreateArticleInfoDialogOpened(false));
  };

  const handleClosePublishInfoDialog = () => {
    dispatch(editorSlice.actions.setPublishInfoDialogOpened(false));
  };

  const handleCloseShareArticleDialog = () => {
    dispatch(editorSlice.actions.setShareArticleDialogOpened(false));
  };
  const handleIntegrationDialogClose = () => {
    dispatch(editorSlice.actions.setCreateIntegrationDialogOpened(false));
    dispatch(editorSlice.actions.setPublishDialogOpened(true));
  };

  const publishArticleDialogClose = () => {
    dispatch(editorSlice.actions.setPublishDialogOpened(false));
  };

  return (
    <>
      <CreateArticleDialog
        onClose={handleCloseArticleDialog}
        open={createArticleDialogOpened || updateArticleDialogOpened}
        update={updateArticleDialogOpened}
      />
      <UpdateIntegrationDialog
        open={updateIntegrationDialogOpened}
        onClose={handleCloseUpdateIntegrationDialog}
        handleCreateIntegration={handleCreateIntegration}
      />
      <CreateIntegrationDialog
        open={createIntegrationOpened}
        onClose={handleCloseCreateIntegration}
      />
      <SelectSourcesDialog
        onClose={handleCloseAddSourcesDialog}
        open={addSourcesDialogOpened.opened}
        update={!!addSourcesDialogOpened.update}
        editorRef={props.editorRef}
      />
      <CreateOutlineInfoDialog
        onClose={handleCloseOutlineInfoDialog}
        open={createOutlineInfoDialogOpened}
      />
      <CreateArticleInfoDialog
        onClose={handleCloseArticleInfoDialog}
        open={createArticleInfoDialogOpened}
      />
      <PublishInfoDialog
        onClose={handleClosePublishInfoDialog}
        open={publishInfoDialogOpened}
      />
      <ShareDialog
        onClose={handleCloseShareArticleDialog}
        open={shareArticleDialogOpened}
      />
      <CreateIntegrationDialog
        onClose={handleIntegrationDialogClose}
        open={createIntegrationDialogOpened}
      />
      <PublishArticleDialog
        onClose={publishArticleDialogClose}
        open={publishDialogOpened}
      />
    </>
  );
};
