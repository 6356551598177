import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectResponse } from "../../interfaces/projects.interface";

type ProjectsFilter = {
  integrationFilter?: string | "";
  nameFilter?: string | "";
  filtered: boolean;
};

interface IntegrationsState {
  projects: Array<ProjectResponse>;
  projectsCount: number;
  projectsFilteredCount: number;
  page: number;
  filter: ProjectsFilter;
  createDialogOpened: boolean;
}

const initialState: IntegrationsState = {
  projects: [],
  projectsCount: 0,
  projectsFilteredCount: 0,
  page: 1,
  filter: { nameFilter: "", integrationFilter: "", filtered: false },
  createDialogOpened: false,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Array<ProjectResponse>>) => {
      state.projects = action.payload;
    },
    setProjectsFilteredCount: (state, action: PayloadAction<number>) => {
      state.projectsFilteredCount = action.payload;
    },
    setProjectsCount: (state, action: PayloadAction<number>) => {
      state.projectsCount = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setNameFilter: (state, action: PayloadAction<string | "">) => {
      state.filter = {
        ...state.filter,
        nameFilter: action.payload,
        filtered: action.payload !== "",
      };
      state.page = 1;
    },
    setIntegrationFilter: (state, action: PayloadAction<string | "all">) => {
      state.filter = {
        ...state.filter,
        integrationFilter: action.payload,
        filtered: action.payload !== "",
      };
      state.page = 1;
    },
    resetFilter: (state, action: PayloadAction<undefined>) => {
      state.filter = initialState.filter;
      state.page = 1;
    },
    setCreateDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.createDialogOpened = action.payload;
    },
  },
});

export type { IntegrationsState, ProjectsFilter };
export const { } = projectsSlice.actions;
export default projectsSlice;
