import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaddleSubscription {
  amount: string | null;
  inTrial: boolean;
  kind: "addon" | "primary" | "hidden";
  name: string;
  nextBilledAt: string | null;
  paddleSubscriptionId: string;
  priceId: string;
  status: string;
  subscribed: string;
}

interface PaddleAvailableAddon {
  priceId: string;
  name: string;
  description: string;
  amount: string;
}

interface PaddleAPIResponse {
  activePlans: PaddleSubscription[];
  activeAddons: PaddleSubscription[];
  availableAddons: PaddleAvailableAddon[];
}

interface BillingState {
  paddle: PaddleAPIResponse;
}

const initialState: BillingState = {
  paddle: {
    activePlans: [],
    activeAddons: [],
    availableAddons: [],
  },
};

const paddleSlice = createSlice({
  name: "paddle",
  initialState,
  reducers: {
    setPaddlePlans: (state: BillingState, action: PayloadAction<PaddleAPIResponse>) => {
      state.paddle = action.payload;
    },
    reset: () => initialState,
  },
});

export type { PaddleAPIResponse, PaddleSubscription, PaddleAvailableAddon };
export default paddleSlice;
