import { Box, Drawer, Stack, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { RichTextEditorRef } from "mui-tiptap/dist/RichTextEditor";
import { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import { PanelButton } from "../../../common/layouts/editor/panel-button";
import { useAppDispatch } from "../../../core/hooks/useStore";
import { RootState } from "../../../core/store/store";
import { Articles } from "./panel-articles";
import { Settings } from "./panel-settings";
import { Sources } from "./panel-sources";

const SIDE_NAV_WIDTH = 300;
const SIDE_NAV_COLLAPSED_WIDTH = 73; // icon size + padding + border right

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
};

export const Panel = (props: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const editorState = useSelector((state: RootState) => state.editor);
  const collapse = false;
  const [panelTab, setPanelTab] = useState<string>("sources");
  const dispatch = useAppDispatch();
  const [settingsDirty, setSettingsDirty] = useState<boolean>(false);

  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  if (mdDown) return <></>;

  return (
    <Drawer
      open
      variant="permanent"
      anchor="right"
      PaperProps={{
        onMouseEnter: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: "background.default",
          height: `calc(100% - 20px)`,
          overflowX: "hidden",
          overflowY: "hidden",
          top: "30px",
          transition: "width 250ms ease-in-out",
          px: "10px",
          width: collapse ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
          zIndex: (theme) => theme.zIndex.appBar - 100,
        },
      }}
    >
      <Stack direction="row" justifyContent="center">
        <PanelButton editorRef={props.editorRef} />
      </Stack>
      <Box sx={{ padding: "10px", marginTop: "20px" }}>
        <Stack direction="row" justifyContent="center">
          <ToggleButtonGroup exclusive size={"small"} value={panelTab} disabled={editorState.article?.generating}>
            <ToggleButton value="sources" onClick={() => setPanelTab("sources")}>
              Sources
            </ToggleButton>
            <ToggleButton value="settings" onClick={() => setPanelTab("settings")}>
              {settingsDirty ? "Settings (unsaved)" : "Settings"}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
      {panelTab === "sources" && <Sources />}
      {panelTab === "settings" && <Settings editorRef={props.editorRef} onDirtyChange={setSettingsDirty} />}
    </Drawer>
  );
};
