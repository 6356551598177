import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  alpha,
} from "@mui/material";
import { ArticleDataSource } from "../../../interfaces/article.interface";

export const SourceDialogCard = (props: {
  data: ArticleDataSource;
  selected: boolean;
  onSelect: Function;
}) => {
  return (
    <Card
      sx={{
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: (theme) =>
          props.selected
            ? alpha(theme.palette.primary.main, 0.5)
            : theme.palette.grey[300],
        boxShadow: props.selected ? 3 : 0,
        my: "10px",
        mx: "20px",
        "&:hover": {
          cursor: "pointer",
        },
        userSelect: "none",
      }}
      onClick={() => {
        props.onSelect();
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {props.data.data.domain}
        </Typography>
        <Typography variant="h5" sx={{ fontSize: 15 }}>
          {props.data.title}
        </Typography>
        <Typography variant="body2">{props.data.data.desc}</Typography>
      </CardContent>

      <CardActions sx={{ paddingTop: "0px" }}>
        {!props.selected ? (
          <Button size="small" sx={{ color: "text.secondary" }}>
            Add Source
          </Button>
        ) : (
          <Button size="small">Remove Source</Button>
        )}
        {props.data.data.url && (
          <Button
            href={props.data.data.url}
            target="_blank"
            size="small"
            sx={!props.selected ? { color: "text.secondary" } : {}}
          >
            Open
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
