import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import integrationsSlice from "./integrations.slice";

type Props = {
  integrationName: string;
  integrationID: string;
  open: boolean;
  onClose: any;
};

export const DeleteIntegrationDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
      dispatch(integrationsSlice.actions.setIntegrationsCount(result.count));
      dispatch(integrationsSlice.actions.setPage(1));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
    setLoading(false);
  };

  const deleteIntegration = async () => {
    try {
      setLoading(true);
      const data = {};
      const response: any = await axios.post(`${apiUrl}/integration/delete`, {
        id: props.integrationID,
      });
      const result = response.data.result;
      await getIntegrations();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error deleting integration...");
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      //   }}
    >
      <DialogTitle>Disconnect Integration: {props.integrationName}</DialogTitle>
      <DialogContent>Are you sure you want to disconnect the integration, {props.integrationName}?</DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
        <LoadingButton loading={loading} onClick={deleteIntegration} variant="contained">
          Disconnect Now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
