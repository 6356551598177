import NichePigBasicImg from "./niche-pig-basic.png";
import NichePigCardImg from "./niche-pig-card.png";

interface ToolItem {
  name: string;
  path: string;
  key: string;
  desc: string;
  img: any;
  checkout: {
    dependentsNotMetCheckout?: string;
    checkout: string;
  };
  dependents: string[];
  free?: boolean;
}

const tools: ToolItem[] = [
  {
    name: "Niche Pig Pro",
    path: "/tools/niche-tool",
    key: "nicheTool",
    desc: "Niche Pig Pro Makes It Easy To Find Profitable Niches In Just A Few Clicks! Simply Enter An Interest And Let Niche Pig Do The Research For You.",
    img: NichePigCardImg,
    checkout: {
      checkout: "https://www.nichepigpro.com/checkout",
    },
    dependents: [],
  },
  {
    name: "Headline Pig Pro",
    path: "/tools/headline-tool",
    key: "headlineTool",
    desc: "Headline Pig Pro Creates An Entire Posting Plan For You! Just Enter A Niche And Let Headline Pig Find Buyer-Intent Headlines Research For You.",
    img: NichePigCardImg,
    checkout: {
      checkout: "https://www.nichepigpro.com/expansion",
      dependentsNotMetCheckout: "https://www.nichepigpro.com/checkout",
    },
    dependents: ["nicheTool"],
  },
  {
    name: "Keyword Pig Pro",
    path: "/tools/keyword-tool",
    key: "keywordTool",
    desc: "Niche Pig Pro Finds Buyer-Intent Keywords In a Matter of Seconds! Provide It With a Niche And Get a List of Buyer-Intent Keywords and Topics",
    img: NichePigCardImg,
    checkout: {
      checkout: "https://www.nichepigpro.com/expansion",
      dependentsNotMetCheckout: "https://www.nichepigpro.com/checkout",
    },
    dependents: ["nicheTool"],
  },
  {
    name: "Niche Pig",
    path: "https://wordform.ai/niche-generator/",
    key: "nicheToolBasic",
    desc: "Niche Pig Is The First Iteration of Our Powerful Niche Research Technology! You Can Access This On The Wordform AI Homepage...",
    img: NichePigBasicImg,
    checkout: {
      checkout: "#",
      dependentsNotMetCheckout: "#",
    },
    dependents: [],
    free: true,
  },
  {
    name: "Keyword Pig",
    path: "https://wordform.ai/keyword-generator/",
    key: "keywordToolBasic",
    desc: "Keyword Pig Is The First Iteration of Our Powerful Niche Research Technology! You Can Access This On The Wordform AI Homepage...",
    img: NichePigBasicImg,
    checkout: {
      checkout: "#",
      dependentsNotMetCheckout: "#",
    },
    dependents: [],
    free: true,
  },
  {
    name: "Headline Pig",
    path: "https://wordform.ai/headline-generator/",
    key: "headlineToolBasic",
    desc: "Headline Pig Is The First Iteration of Our Powerful Niche Research Technology! You Can Access This On The Wordform AI Homepage...",
    img: NichePigBasicImg,
    checkout: {
      checkout: "#",
      dependentsNotMetCheckout: "#",
    },
    dependents: [],
    free: true,
  },
];

export type { ToolItem };
export default tools;
