import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../common/components/loader";
import { RootState } from "../../core/store/store";
import { TemplateResponse } from "../../interfaces/template.interface";

type Props = {
  open: boolean;
  onClose: any;
};

type CreateProjectData = {
  name: string;
};

export const CreateProjectDialog = (props: Props) => {
  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Array<TemplateResponse>>([]);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<CreateProjectData>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CreateProjectData> = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/project/create`, data);
      toast.success("Successfully created project!");
      navigate(`/editor/${response.data.result.id}`);
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating project");
      }
    }
    reset();
    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create Project</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Project Name"
              disabled={loading}
              {...register("name", { required: true, minLength: 1 })}
            />
            {formState.errors.name && <FormHelperText error={true}>Please enter a project name!</FormHelperText>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton loading={loading} type="submit" variant="contained" disabled={!formState.isValid || loading}>
            Create Project
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
