import PlusIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, Pagination, Stack, SvgIcon, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../common/components/loader";
import DashboardLayout from "../../common/layouts/dashboard";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import { useDialog } from "../../hooks/use-dialog";
import { CreateIntegrationDialog } from "./create-integration-dialog";
import IntegrationsCard from "./integrations-card";
import IntegrationsEmpty from "./integrations-empty";
import integrationsSlice from "./integrations.slice";

const Integrations = () => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const integrationState = useSelector((state: RootState) => state.integrations);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const createDialog = useDialog();
  const page = integrationState.page;

  useEffect(() => {
    document.title = "Wordfrom AI - Integrations";
  }, []);

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const page = integrationState.page;
      const data = { page, pageSize: 6 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
      dispatch(integrationsSlice.actions.setIntegrationsCount(result.count));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
    setLoading(false);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(integrationsSlice.actions.setPage(value));
  };

  useEffect(() => {
    getIntegrations();
  }, [page]);

  return (
    <DashboardLayout active={"integrations"} name="Integrations">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h4">Integrations</Typography>
        <Button
          onClick={createDialog.handleOpen}
          size="large"
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
        >
          Create
        </Button>
      </Stack>
      {loading && <Loader />}
      {!loading && integrationState.integrations.length === 0 && <IntegrationsEmpty dialog={createDialog} />}
      {!loading && integrationState.integrations.length > 0 && (
        <>
          <Box sx={{ my: 3 }}>
            <Grid container spacing={2}>
              {integrationState.integrations.map((integration, k) => {
                return (
                  <IntegrationsCard
                    key={integration.id}
                    id={integration.id}
                    domain={integration.data.domain as string}
                  />
                );
              })}
            </Grid>
          </Box>
          <Stack sx={{ my: 4 }} justifyContent="center" alignItems="center">
            <Pagination
              count={Math.ceil(integrationState.integrationsCount / 6)}
              variant="outlined"
              shape="rounded"
              size="large"
              onChange={handlePageChange}
              page={page}
            />
          </Stack>
        </>
      )}

      <CreateIntegrationDialog onClose={createDialog.handleClose} open={createDialog.open} />
    </DashboardLayout>
  );
};

export default Integrations;
